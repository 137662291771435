import React from 'react';
import { meetGetModeratorLink } from '../../actions';
import Action from '../../../../Action';
import { PlayCircleFilled } from '@mui/icons-material';
import { useDispatch } from 'react-redux';


function Play({ meet, meetURL }) {
    const dispatch = useDispatch();
    return (
        <Action
            title={'meetsList.play'}
            icon={<PlayCircleFilled />}
            onClickHandle={
                () => {
                    dispatch(meetGetModeratorLink(meet._id, meetURL, meet.roomId, true))
                }
            }
        >
        </Action>
    )
}

export default Play;
