import React from 'react';
import { withTranslation } from 'react-i18next';
import { Email } from '@mui/icons-material';
import Action from '../../../../Action';



function SendToEmail({ meet, date, meetURL, urlBase, t }) {
    let mailLink =
        `mailto:`
        + `?subject=${t('meetsList.email.subject')}`
        + `&body=${t('meetsList.email.body')}:`
        + `%0D`;

    if (meet.room) {
        mailLink +=
            `${t('meetsList.email.roomDescription')}: ${meet.description}`
            + `%0D`
            + `${t('meetsList.email.roomId')}: ${meet.roomId}`
            + `%0D`
            + `${t('meetsList.email.link')}: ${meetURL}`
            + `%0D`;
    } else {
        const meetDate = meet.periodic ? `${t('meetForm.periodic')} – ${date}` : date;
        const timeZoneOffset = -(new Date().getTimezoneOffset() / 60);
        const timeZone = `GMT` + (timeZoneOffset >= 0 ? `+${timeZoneOffset}` : timeZoneOffset);

        mailLink +=
            `${t('meetsList.email.date')}: ${meetDate} (${timeZone})`
            + `%0D`
            + `${t('meetsList.email.description')}: ${meet.description}`
            + `%0D`
            + `${t('meetsList.email.calendar')}: ${urlBase}/calendar/${meet._id}`
            + `%0D%0D`
            + `${t('meetsList.email.link')}: ${meetURL}/${meet._id}`
            + `%0D`;
    }

    mailLink +=
        Boolean(meet.pass) ? `${t('meetForm.passEnabled')}: ${meet.pass}%0D%0D` : `%0D`;

    return (
        <Action
            title={'meetsList.sendToEmail'}
            icon={<Email />}
            onClickHandle={
                () => {
                    window.location = mailLink
                }
            }
        >
        </Action>
    )
}

export default withTranslation()(SendToEmail);
