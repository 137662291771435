const meetStatuses = {
    // Not show info for this block of statuses
    LOADING: 'loading',
    ERROR: 'error',
    NOTFOUND: 'not_found',
    FINISHED: 'finished',

    // Show info for this block of statuses
    TOOEARLY: 'too_early',
    SHOWLINK: 'show_link',
    NOTSTARTED: 'not_started',

    // License limits statuses
    // Shows on link/button click
    PARTICIPANTSLIMIT: 'participants_limit',
    ORGLIMIT: 'org_limit',
    LICENSEUSED: 'license_used',

    // Show webinar reg form
    WEBINAR_REG: 'webinar_reg',
    // Show notification about succeeded registration
    WEBINAR_REG_SUCCEEDED: 'webinar_reg_succeeded',

    // Render iframe
    STARTED: 'started'
};


export default meetStatuses;
