import React from 'react';
import { useSelector } from 'react-redux';
import {
    List
} from '@mui/material';

import AdminUsersItem from './AdminUsersItem';
import AdminLicensesItem from './AdminLicensesItem'
import { USER_DEFAULT_ADMIN_NAME, USER_PERMS } from '../../const';
import SettingsItem from './SettingsItem';

function AdminMenu() {
    const { _user, _LDAPEnabled } = useSelector(state => {
        return {
            _user: state.app.user,
            _LDAPEnabled: state.app.settings.LDAPEnabled
        }
    });

    if (_user.role !== USER_DEFAULT_ADMIN_NAME && !_user.isAdmin)
        return null;

    return (
        <List>
            {_LDAPEnabled ? _user.perms.includes(USER_PERMS.SETTINGS) ? <SettingsItem /> : null : null}
            {_user.perms.includes(USER_PERMS.LICENSES) ? <AdminLicensesItem /> : null}
            {_user.perms.includes(USER_PERMS.USERS) ? <AdminUsersItem /> : null}
        </List>
    )

}
export default (AdminMenu);
