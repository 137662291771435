import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { usersFormFieldChangedPassword } from "../../users/actions";

function FormPassword({ t }) {
    const dispatch = useDispatch();
    const { _changePassword } = useSelector(state => {
        return {
            _changePassword: state.users.changePassword,
        }
    });
    const [searchTerm, setSearchTerm] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
    const helperText = showErrorMessage && isCPasswordDirty && t('profile.form.new_password_not_match');

    useEffect(() => {
        if (_changePassword.password === _changePassword.passwordConfirm) {
            setShowErrorMessage(false);
        } else {
            setShowErrorMessage(true);
        }
    }, [_changePassword]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                handleSubmit();
            }
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    function handleSubmit(e) {
        setIsCPasswordDirty(true);
        _changePassword.newPassword === _changePassword.newPasswordConfirm ? setShowErrorMessage(false) : setShowErrorMessage(true);
    }
    function handleInputChange(value) {
        if (value) {
            setSearchTerm(value)
        }
    }

    return (
        <>

            <form onSubmit={() => { }}>
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    type="password"
                    fullWidth
                    // required={!isEditing}
                    autoComplete="off"
                    label={t('profile.form.oldPassword')}
                    name="oldPassword"
                    // value={_form.password}
                    onChange={event => dispatch(usersFormFieldChangedPassword({ field: event.target.name, value: event.target.value }))}
                />
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    type="password"
                    fullWidth
                    autoComplete="off"
                    label={t('profile.form.newPassword')}
                    name="newPassword"
                    value={_changePassword.newPassword}
                    onChange={event => {
                        dispatch(usersFormFieldChangedPassword({ field: event.target.name, value: event.target.value }))
                    }
                    }

                />
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    type="password"
                    disabled={_changePassword.password == ''}
                    fullWidth
                    error={showErrorMessage && isCPasswordDirty}
                    autoComplete="off"
                    label={t('profile.form.newPasswordConfirm')}
                    name="newPasswordConfirm"
                    value={_changePassword.newPasswordConfirm}
                    helperText={helperText}
                    onChange={event => {
                        dispatch(usersFormFieldChangedPassword({ field: event.target.name, value: event.target.value }));
                        handleInputChange(event.target.value)

                    }
                    }

                />

            </form>
        </>
    )

}
export default withTranslation()(FormPassword)