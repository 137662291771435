import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';


function Limits({ t }) {
    const { _meet } = useSelector(state => {
        return {
            _meet: state.meet
        }
    })
    const { limitStatus, limit } = _meet;
    if (limitStatus) {
        return (
            <p className="limits">{t(`meet.${limitStatus}`, { limit })}</p>
        )
    }
    return null;
}


export default withTranslation()(Limits);
