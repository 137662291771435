import {
    DRAWER_TOGGLED,
    MENU_CHANGED,
    SHOW_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SHOW_CONFIRM_DIALOG,
    CLOSE_CONFIRM_DIALOG,
    SHOW_MODAL,
    CLOSE_MODAL,
    SHOW_MODAL_PREVIEW,
    CLOSE_MODAL_PREVIEW
} from './actionTypes';


export function drawerToggled() {
    return {
        type: DRAWER_TOGGLED
    }
}


export function menuChanged(payload) {
    return {
        type: MENU_CHANGED,
        payload
    }
}


export function showNotification(payload) {
    return {
        type: SHOW_NOTIFICATION,
        payload
    }
}


export function closeNotification() {
    return {
        type: CLOSE_NOTIFICATION
    }
}


export function showConfirmDialog(payload) {
    return {
        type: SHOW_CONFIRM_DIALOG,
        payload
    }
}


export function closeConfirmDialog() {
    return {
        type: CLOSE_CONFIRM_DIALOG
    }
}


export function showModal(payload) {
    return {
        type: SHOW_MODAL,
        payload
    }
}


export function closeModal(payload) {
    return {
        type: CLOSE_MODAL,
        payload
    }
}

export function showModalPreview(payload) {
    return {
        type: SHOW_MODAL_PREVIEW,
        payload
    }
}
export function closeModalPreview(payload) {
    return {
        type: CLOSE_MODAL_PREVIEW,
        payload
    }
}