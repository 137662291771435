import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import LDAP from "./LDAP/LDAP";
import GeneralSettings from "./generalSettings/GeneralSettings";
import { useSelector } from "react-redux";

function SettingsTabs() {
    const { t } = useTranslation();
    const [value, setValue] = useState('0');
    const { _LDAPEnabled } = useSelector(state => {
        return {
            _LDAPEnabled: state.app.settings.LDAPEnabled
        }
    })
    const onClick = (value) => {
        setValue(value);
    };
    const settingsTabsMenu = [{ label: t('settings.ldap.title'), content: <LDAP /> }];
    // const settingsTabsMenu = [{ label: t('settings.general'), content: <GeneralSettings /> }];
    // if (_LDAPEnabled) {
    //     settingsTabsMenu.push({ label: t('settings.ldap.title'), content: <LDAP /> })
    // }

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList aria-label="lab API tabs example">
                        {settingsTabsMenu.map((item, index) => {
                            return <Tab label={item.label} value={(index).toString()} key={`tab_${index}`} onClick={() => onClick((index).toString())} />
                        })}
                    </TabList>
                </Box>
                {settingsTabsMenu.map((item, index) => {
                    return <TabPanel value={(index).toString()} key={`tab_${index}`}>{item.content}</TabPanel>
                })}
            </TabContext>
        </>
    )
}
export default SettingsTabs