import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { connectorsLoad } from './actions';
import Items from './Items';


function Connectors() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(connectorsLoad());
    }, [])
    const { t } = useTranslation()
    return (
        <Box className="users">
            <TableContainer >
                <Table aria-label="orgsTable">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t('settings.ldap.connectors.name')}</TableCell>
                            <TableCell align="center" className="exp">{t('settings.ldap.connectors.domain')}</TableCell>
                            <TableCell align="center" className="exp">{t('settings.ldap.connectors.baseDN')}</TableCell>
                            <TableCell align="center" className="exp">{t('settings.ldap.connectors.timeSync')}</TableCell>
                            <TableCell align="center" className="exp">{t('settings.ldap.connectors.syncStatus')}</TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <Items />
                    </TableBody>

                </Table>
            </TableContainer>
        </Box>
    )
}


export default Connectors;