import i18n from "i18next";
import Backend from 'i18next-http-backend';
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


i18n
.use(Backend)
.use(languageDetector)
.use(initReactI18next)
.init({
    debug: false,
    fallbackLng: 'ru',
    detection: {
        checkWhitelist: true,
        order: ['navigator', 'localStorage', 'cookie']
    },
    whitelist: ['ru', 'tat', 'en'],
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: true
    },
    backend: {
        loadPath: () => {
            return `/mint_static/locales/{{lng}}/{{ns}}.json?v=${ window.mintBuildTS }`;
        }
    }
});


export default i18n;
