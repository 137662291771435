import React from 'react';

import { useSelector } from 'react-redux';

import { withTranslation } from 'react-i18next';

import {
    Tooltip,
    IconButton
} from '@mui/material';

function Action({ showIfSecretary,
    disabled,
    title,
    icon,
    onClickHandle,
    t }) {
    const { _user } = useSelector(state => {
        return {
            _user: state.app.user
        }
    })

    if ((showIfSecretary && !_user.isSecretary) || disabled)
        return null;

    return (
        <Tooltip title={t(title)} arrow>
            <IconButton
                onClick={(event) => onClickHandle(event)}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export default withTranslation()(Action);
