import React from "react";
import { useDispatch } from "react-redux";
import { Delete as DeleteIcon } from '@mui/icons-material';
import { connectorDelete } from "../actions";
import { showConfirmDialog } from "../../../../actions";
import Action from "../../../../Action";

function Delete({ connector }) {
    const dispatch = useDispatch();
    return (
        <Action
            title={'settings.ldap.delete.title'}
            icon={<DeleteIcon />}
            onClickHandle={
                () => {
                    dispatch(
                        showConfirmDialog({
                            title: 'settings.ldap.delete.title',
                            text: {
                                key: 'settings.ldap.delete.deleteText',
                                vars: { connector: connector.name }
                            },
                            actionButton: 'settings.ldap.delete.button',
                            action: connectorDelete({ id: connector._id }),

                        })
                    )
                }
            }
        >
        </Action>
    )
}
export default Delete