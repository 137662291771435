import React from "react";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { withTranslation } from "react-i18next";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";


function AbstractColorPicker({ t, label, icon, handleClickPicker, handleChangePicker, colorStyle }) {
    const handleClick = () => {
        handleClickPicker()
    };


    const handleChange = (color) => {
        handleChangePicker(color);
    };
    const [textButton, setTextButton] = useState(true)

    const styles = reactCSS({
        'default': {
            color: {
                width: '100%',
                height: '100%',
                borderRadius: '2px',
                background: `${colorStyle.color}`,
            },
            swatch: {
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                height: '10rem',
                width: "100%",
                maxWidth: '100%'
            },
            popover: {
                position: 'absolute',
                bottom: '0',
                right: '0',
                transform: 'translate(-80%,20%)',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <Box sx={{ 'position': 'relative' }}>
            {label ? <Typography sx={{ mb: 2 }}>{label}</Typography> : null}
            <Grid item style={styles.swatch} xs={8} sx={{ 'position': 'relative' }} className="colorPickerContainer">

                <Box fontSize='large' sx={{ 'position': 'absolute', 'top': '0', 'right': '0' }} className="colorizeHover">
                    <Button variant="contained"
                        onClick={(e) => {
                            handleClick();
                            setTextButton(prevState => !prevState)
                        }}>{textButton ? t('orgs.paletteSettings.selectColorBtn') : t('orgs.paletteSettings.close')}</Button>
                </Box>
                <Box style={styles.color} />

            </Grid>
            <Box className="colorPicker">
                {colorStyle.displayColorPicker ?
                    <Box style={styles.popover}>
                        <Box style={styles.cover} />
                        <SketchPicker color={colorStyle.color} onChange={handleChange} />
                    </Box>
                    : null}
            </Box>
        </Box>
    )

}

export default withTranslation()(AbstractColorPicker)  