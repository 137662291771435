
import {
    LICENSES_LOADED,
    LICENSES_AVAILABLE_LOADED,
    NEW_FILTER_LICENSES,
    UPLOAD_LICENSE_FILE,
    SEND_UPLOAD_FILE_CLEAR
} from './actionTypes';


const licensesState = {
    // List of all licenses for Licenses page
    licenses: [],

    // Pool licenses state
    participantsLicensed: 0,
    participantsAvailable: 0,

    // Licenses for meets by org
    peoplesLicensed: 0,
    peoplesAvailable: 0,
    licensesAvailable: [],
    // Filter licenses (end, current, future)
    filterLicenses: {},
    licenseFile: null,

};

export function licensesReducer(state = licensesState, action) {
    switch (action.type) {
        case LICENSES_LOADED:
            return {
                ...state,
                licenses: action.payload.licenses,
                participantsLicensed: action.payload.participantsLicensed || 0,
                participantsAvailable: action.payload.participantsAvailable || 0
            };

        case LICENSES_AVAILABLE_LOADED:
            return {
                ...state,
                peoplesLicensed: action.payload.peoplesLicensed,
                peoplesAvailable: action.payload.peoplesAvailable,
                licensesAvailable: action.payload.licensesAvailable
            }
        case NEW_FILTER_LICENSES:
            return {
                ...state,
                filterLicenses: action.payload
            }
        case UPLOAD_LICENSE_FILE:
            return {
                ...state,
                licenseFile: action.payload
            }
        case SEND_UPLOAD_FILE_CLEAR:
            return {
                ...state,
                licenseFile: null
            }
        default:
            return state;
    }
}
