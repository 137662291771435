import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { meetsListUpdate } from '../../actions';
import { showModal } from '../../../../actions';
import { ArchiveTwoTone } from '@mui/icons-material';
import Action from '../../../../Action';
import Archive from '../archive/Archive';



function GetRecs({ meet }) {
    const { _settings, _user, _filter } = useSelector(state => {
        return {
            _settings: state.app.settings,
            _user: state.app.user,
            _filter: state.meets.filter
        }
    })
    const dispatch = useDispatch()
    if (_settings.restrictedArchive && (!_user.isAdmin && !_user.allowArchive))
        return null;

    return (
        <Action
            title={'meetsList.download'}
            icon={<ArchiveTwoTone />}
            onClickHandle={
                () => {
                    dispatch(
                        showModal({
                            title: 'archive.title',
                            form: <Archive meet={meet} />,
                            actionButton: '',
                            action: {},
                            closeAction: { action: meetsListUpdate({ filter: _filter }) }
                        })
                    )
                }
            }
        >
        </Action>
    )
}

export default withTranslation()(GetRecs);
