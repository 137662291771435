export const AUTH_CHECK = 'AUTH_CHECK';

export const AUTH_CHECKED = 'AUTH_CHECKED';

export const USER_UPDATED = 'USER_UPDATED';

export const TS_UPDATED = 'TS_UPDATED';

export const LOG_IN = 'LOG_IN';

export const LOGGED_IN = 'LOGGED_IN';

export const LOG_IN_FAILED = 'LOG_IN_FAILED';

export const LOG_OUT = 'LOG_OUT';

export const LNG_CHANGED = 'LNG_CHANGED';

export const BACKDROP_TOGGLED = 'BACKDROP_TOGGLED';

export const IS_LICENSES_LOADED_FILE = 'IS_LICENSES_LOADED_FILE';
