import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardColorPicker } from "../../../actions";
import { useState } from "react";
import AbstractColorPicker from "./AbstractColorPicker";

function InfoColorPicker({ label, icon, org }) {
    const { _prejoinInfoBgColor } = useSelector(state => {
        return {
            _prejoinInfoBgColor: state.users.paletteSettings.prejoinInfoBgColor,
            _prejoinInfoFontColor: state.users.paletteSettings.prejoinInfoFontColor
        }
    })
    const dispatch = useDispatch();
    const [background, setBackground] = useState({
        displayColorPicker: false,
        color: `${_prejoinInfoBgColor ? _prejoinInfoBgColor : 'rgba(245,245,245,1)'}`
    })

    useEffect(() => {
        setBackground({ ...background, color: _prejoinInfoBgColor })
    }, [_prejoinInfoBgColor])
    const handleClickBackground = () => {
        setBackground({ ...background, displayColorPicker: !background.displayColorPicker });
        dispatch(dashboardColorPicker(background.color))
    };

    const handleChange = (color) => {
        setBackground({ ...background, color: `rgba(${(Object.values(color.rgb)).join()})` });
        dispatch(dashboardColorPicker(`rgba(${(Object.values(color.rgb)).join()})`))
    };

    return (
        <AbstractColorPicker handleClickPicker={handleClickBackground} handleChangePicker={handleChange} colorStyle={background} label={label} icon={icon} />
    )
}
export default InfoColorPicker