import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardColorPickerBg } from "../../../actions";
import { useState } from "react";
import AbstractColorPicker from "./AbstractColorPicker";
import { Box } from "@mui/material";

function BgColorPicker({ label, icon }) {
    const { _prejoinBgColor } = useSelector(state => {
        return {
            _prejoinBgColor: state.users.paletteSettings.prejoinBgColor,
        }
    })

    const dispatch = useDispatch();
    const [background, setBackground] = useState({
        displayColorPicker: false,
        color: _prejoinBgColor ? _prejoinBgColor : 'rgba(54,58,72,1)'
    });

    useEffect(() => {
        setBackground({ ...background, color: _prejoinBgColor })
    }, [_prejoinBgColor])
    const handleClickBackground = () => {
        setBackground({ ...background, displayColorPicker: !background.displayColorPicker });
        dispatch(dashboardColorPickerBg(background.color));
    };


    const handleChange = (color) => {
        setBackground({ ...background, color: `rgba(${(Object.values(color.rgb)).join()})` });
        dispatch(dashboardColorPickerBg(`rgba(${(Object.values(color.rgb)).join()})`));
    };

    return (
        <Box sx={{ height: '13rem' }}>
            <AbstractColorPicker handleClickPicker={handleClickBackground} handleChangePicker={handleChange} colorStyle={background} label={label} icon={icon} />
        </Box>
    )
}
export default BgColorPicker