import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { showConfirmDialog } from '../../../../actions';
import {
    archiveDeleteFile,
    archiveDownloadFile
} from './actions';
import {
    GetApp,
    Link,
    Delete
} from '@mui/icons-material';
import Action from '../../../../Action';


function Buttons({ fileType, meetId, file, fileName, fileIndex }) {
    const dispatch = useDispatch()
    return (
        <>
            <Action
                title={'archive.download'}
                icon={<GetApp />}
                onClickHandle={
                    () => {
                        dispatch(
                            archiveDownloadFile(fileType, meetId, file, fileIndex)
                        )
                    }
                }
            />

            <Action
                title={'archive.copyLink'}
                icon={<Link />}
                onClickHandle={
                    () => {
                        dispatch(
                            archiveDownloadFile(fileType, meetId, file, fileIndex, true)
                        )
                    }
                }
            />

            <Action
                title={'archive.delete'}
                icon={<Delete />}
                onClickHandle={
                    () => {
                        dispatch(
                            showConfirmDialog({
                                title: 'archive.deleteTitle',
                                text: {
                                    key: 'archive.deleteText',
                                    vars: { file: fileName }
                                },
                                actionButton: 'archive.delete',
                                action: archiveDeleteFile(fileType, meetId, file, fileIndex)
                            })
                        )
                    }
                }
            />
        </>
    )
}



export default connect()(Buttons);
