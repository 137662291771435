import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import Logo from "../previews/previewComponents/Logo";

function PreviewApplication({ t, org }) {
    const {
        _appBgColor,
        _appBgImage,
        _appBgImageAsPrejoin,
        _appLogo,
        _locationHost,
        _prejoinBgImage,
        _selectColorApp
    } = useSelector(state => {
        return {
            _appBgColor: state.users.paletteSettings.appBgColor,
            _appBgImage: state.users.paletteSettings.appBgImage,
            _appBgImageAsPrejoin: state.users.paletteSettings.appBgImageAsPrejoin,
            _appLogo: state.users.paletteSettings.appLogo,
            _locationHost: state.app.locationHost,
            _prejoinBgImage: state.users.paletteSettings.prejoinBgImage,
            _selectColorApp: state.users.paletteSettings.selectColorApp
        }
    });


    const previewPeople = [
        {
            name: t('orgs.previewNames.nameOne')

        },
        {
            name: t('orgs.previewNames.nameTwo'),
        },
        {
            name: t('orgs.previewNames.nameThree'),
        },
        {
            name: t('orgs.previewNames.nameFour'),
        }
    ];
    const applicationBackground = {
        backgroundColor: `${_appBgColor && !_selectColorApp ? _appBgColor : 'rgb(60, 58, 66)'}`
    }


    const locationUrl = `https://${_locationHost}`;
    const _appBgImageURL = `${locationUrl}${_appBgImage.url}`
    const backgroundImageApp = {
        backgroundImage: `url(${_appBgImage.image || _appBgImageURL})`,
        backgroundSize: 'cover'
    }
    const prejoinBgImageURL = `${locationUrl}${_prejoinBgImage.url}`
    const backgroundImageAsPrejoin = {
        backgroundImage: `url(${_prejoinBgImage.image || prejoinBgImageURL})`,
        backgroundSize: 'cover'
    }
    return (
        <>
            <Box className="previewApplication" style={(_prejoinBgImage.image || _prejoinBgImage.url) && _appBgImageAsPrejoin ? backgroundImageAsPrejoin : (_appBgImage.image || _appBgImage.url) && _selectColorApp ? backgroundImageApp : applicationBackground}>
                <Box className="">
                    <Logo previewLogo={_appLogo.url ? `${locationUrl}${_appLogo.url}` : _appLogo.image} /></Box>
                <Grid container sx={{
                    minHeight: '85vh',
                    maxHeight: '85vh',
                    px: '73px',
                    display: 'grid',
                    gap: 1,
                    gridTemplateColumns: 'repeat(2, 1fr)',
                }
                }
                >
                    {
                        previewPeople.map((item, index) => {
                            return (
                                <Grid item
                                    key={`item_${index}`}
                                    sx={{
                                        backgroundColor: 'rgb(46,45,51)',
                                        position: 'relative',
                                    }} display="flex" justifyContent="center" alignItems="center">
                                    <Box className="avatar-svg" sx={{
                                        background: 'rgba(232, 105, 156, 0.4)', fontSize: '100px', height: "200px", width: '200px',
                                        borderRadius: '50%',
                                        'display': 'flex',
                                        alignItems: 'center',
                                        color: "#fff",
                                        justifyContent: 'center'

                                    }}>{item.name.substr(0, 1)}</Box>

                                    <Box sx={{
                                        maxWidth: 'min(60 %, 48ch)',
                                        background: 'rgba(0, 0, 0, .6)',
                                        padding: '4px',
                                        borderRadius: '6px',
                                        display: 'inline - block',
                                        position: 'absolute',
                                        left: '50%',
                                        bottom: '0',
                                        transform: 'translate(-50%, -40%)',
                                        color: '#fff',
                                        textAlign: 'center',
                                        textOverflow: 'ellipsis',
                                        fontSize: '12px',
                                        fontWeight: '100',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        lineHeight: '22px',
                                        zIndex: '2'
                                    }}>
                                        {item.name}
                                    </Box>
                                    <VideocamOffIcon sx={{
                                        fill: '#fff',
                                        textAlign: 'center',
                                        textShadow: ' 0 1px 0 rgba(255,255,255,.3), 0 -1px 0 rgba(0,0,0,.7)',
                                        width: '12px',
                                        lineHeight: '22px',
                                        height: '22px',
                                        position: 'absolute',
                                        mr: '5px',
                                        left: '5px',
                                        bottom: '0'
                                    }}></VideocamOffIcon>

                                </Grid>
                            )
                        })
                    }


                </Grid>
            </Box>
        </>
    )
}
export default withTranslation()(PreviewApplication)