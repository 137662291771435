import React from 'react';
import {
    GroupTwoTone
} from '@mui/icons-material';
import pages from '../content/pages';
import AdminMenuItem from './AdminMenuItem';

function AdminUsersItem() {
    return (
        <AdminMenuItem name={pages.ADMIN_USERS} icon={<GroupTwoTone />} text="adminMenu.users" />
    )
}

export default AdminUsersItem;
