import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    orgsLoad,
    orgsPageChanged,
    orgsPerPageChanged
} from '../actions';
import { withTranslation } from 'react-i18next';
import {
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableFooter,
    TableRow,
    TableCell,
    TablePagination
} from '@mui/material';
import Add from './actions/Add';
import Items from './Items';
import { USER_PERMS } from '../../../../const';

function Orgs({ t }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(orgsLoad());
    }, [])
    const { _orgs, _page, _orgsPerPage, _isPoolLicense, _licenses, _participantsLicensed, _participantsAvailable, _ts, _user, _multiDomain, _LDAPEnabled } = useSelector(state => {
        return {
            _orgs: state.users.orgs,
            _page: state.users.page,
            _orgsPerPage: state.users.orgsPerPage,
            _isPoolLicense: state.app.settings.isPoolLicense,
            _licenses: state.licenses.licenses,
            _participantsLicensed: state.licenses.participantsLicensed,
            _participantsAvailable: state.licenses.participantsAvailable,
            _ts: state.app.settings.ts,
            _user: state.app.user,
            _multiDomain: state.app.settings.multiDomain,
            _LDAPEnabled: state.app.settings.LDAPEnabled,
        }
    })
    return (
        <Box className="users">
            {_user.perms.includes(USER_PERMS.ORG_ADD) ? <Add /> : <Box className="orgAdd"></Box>}
            {
                _isPoolLicense
                    ?

                    _user.perms.includes(USER_PERMS.LICENSES) ? <Box className="participantsLicensed">
                        {
                            t(
                                'orgs.participantsLicensed',
                                {
                                    participantsLicensed: _participantsLicensed,
                                    participantsAvailable: _participantsAvailable
                                }
                            )
                        }
                    </Box>
                        : null

                    :
                    null
            }

            <TableContainer component={Paper}>
                <Table aria-label="orgsTable">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t('orgs.name')}</TableCell>
                            {
                                _isPoolLicense ?
                                    <>
                                        <TableCell align="center" className="participants">{t('orgs.participants')}</TableCell>
                                        <TableCell align="center" className="meetsPerTime"> {_multiDomain ? t('orgs.multiDomain.title') : _LDAPEnabled ? t('orgs.bindDN') : t('orgs.meetsPerTime')}</TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell align="center" className="licenses">{t('orgs.licenses')}</TableCell>
                                    </>
                            }
                            <TableCell align="center" className="exp">{t('orgs.exp')}</TableCell>
                            <TableCell align="center" className="users">{t('orgs.users')}</TableCell>
                            <TableCell align="center" className="actions"></TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        _user.perms.includes('orgs') ?
                            <TableBody>
                                <Items
                                    orgs={_orgs}
                                    page={_page}
                                    orgsPerPage={_orgsPerPage}
                                    isPoolLicense={_isPoolLicense}
                                    licenses={_licenses}
                                    ts={_ts}
                                />
                            </TableBody>
                            :
                            <TableBody>
                                <Items
                                    orgs={_orgs}
                                    page={_page}
                                    orgsPerPage={_orgsPerPage}
                                    isPoolLicense={_isPoolLicense}
                                    licenses={_licenses}
                                    ts={_ts}
                                />
                            </TableBody>
                    }

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage={t('pagination.rowsPerPage')}
                                labelDisplayedRows={
                                    pagination => (
                                        `${t('pagination.page')} ${pagination.page + 1}`
                                    )
                                }
                                backIconButtonProps={{ title: t('pagination.backward') }}
                                nextIconButtonProps={{ title: t('pagination.forward') }}
                                colSpan={_isPoolLicense ? 6 : 5}
                                count={_orgs.length}
                                page={_page}
                                onPageChange={(event, nextPage) => dispatch(orgsPageChanged({ page: nextPage }))}
                                rowsPerPage={_orgsPerPage}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onRowsPerPageChange={
                                    (event) => {
                                        dispatch(orgsPerPageChanged({ orgsPerPage: parseInt(event.target.value, 10), page: 0 }))
                                    }
                                }
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
}


export default withTranslation()(Orgs);
