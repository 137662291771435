import React from 'react';
import { useDispatch } from 'react-redux';
import {
    usersFormClear,
    userAdd
} from '../../actions';
import { showModal } from '../../../../actions';
import { PersonAddTwoTone } from '@mui/icons-material';
import Action from '../../../../Action';
import Form from '../../Form';

function AddUser({ org }) {
    const dispatch = useDispatch();
    return (
        <Action
            title={'orgs.addUser'}
            icon={<PersonAddTwoTone />}
            onClickHandle={
                () => {
                    dispatch(
                        showModal({
                            title: 'orgs.addUser',
                            form: <Form org={org} />,
                            actionButton: 'users.add.button',
                            action: userAdd({ org: org._id }),
                            closeAction: { action: usersFormClear() }
                        })
                    )
                }
            }
        />
    )

}

export default AddUser;
