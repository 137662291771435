import {
    AUTH_CHECK,
    AUTH_CHECKED,
    LOG_IN,
    LOGGED_IN,
    LNG_CHANGED,
    LOG_OUT,
} from './actionTypes';
import {
    authCheck,
    authChecked,
    tsUpdated,
    loggedIn,
    logInFailed,
} from './actions';
import i18n from './i18n';
import { appFetch } from './functions';
import { showNotification } from '../main/actions';

export function appMiddleware({ getState, dispatch }) {
    return function (next) {
        return function (action) {
            const state = getState();
            switch (action.type) {
                case AUTH_CHECK: {
                    const fetchStart = Date.now();
                    appFetch(
                        state.app.backEnd + '/api/login/is_logged_in',
                        {
                            credentials: 'include'
                        },
                        (data) => {
                            if (data.settings) {
                                const fetchDelay = Date.now() - fetchStart;
                                dispatch(
                                    authChecked({
                                        user: { ...data.user, isAuthChecked: true },
                                        orgsMap: { ...data.orgsMap },
                                        settings: {
                                            ...data.settings,
                                            ts: data.settings.ts + fetchDelay
                                        },
                                        userRoles: data.userRoles

                                    })
                                );

                                setInterval(() => {
                                    dispatch(tsUpdated())
                                }, state.app.tsUpdateInterval)

                                if (data.settings.title) {
                                    document.getElementsByTagName('title')[0].innerText = data.settings.title;
                                }
                            }
                        },
                        dispatch,
                        () => {
                            setTimeout(() => dispatch(authCheck()), 10000)
                        },
                        false
                    );

                    return;
                }
                case AUTH_CHECKED: {
                    break;
                }

                case LOG_IN: {
                    let username = action.payload.username
                    if (state.app.settings.multiDomain && state.app.settings.domain !== window.location.hostname) username = `${action.payload.username}@${window.location.hostname}`
                    appFetch(
                        state.app.backEnd + '/api/login',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: 'username=' + username + '&password=' + encodeURIComponent(action.payload.password)
                        },
                        (data) => {
                            if (data.status === 'ok' && data.user?.name && data.orgsMap && data.settings)
                                dispatch(loggedIn({ user: data.user, orgsMap: data.orgsMap, settings: data.settings, userRoles: data.userRoles }));
                            else if (data.status === 'auth_failed')
                                dispatch(logInFailed({ error: 'loginPage.badAuth' }));
                            else if (data.status === 'auth_denied')
                                dispatch(logInFailed({ error: 'loginPage.authDenied' }));
                            else if (data.status === 'user_not_active')
                                dispatch(logInFailed({ error: 'loginPage.userNotActive' }));
                            else if (data.status === 'org_exp_date')
                                dispatch(logInFailed({ error: 'loginPage.orgExpDate' }));
                            else if (data.status === 'ldap_auth_failed')
                                dispatch(logInFailed({ error: 'loginPage.ldap.ldapAuthFailed' }));
                            else if (data.status === 'contact_the_administrator')
                                dispatch(logInFailed({ error: 'loginPage.ldap.contactTheAdministrator' }));
                            else if (data.status === 'user_domain_is_wrong')
                                dispatch(logInFailed({ error: 'loginPage.ldap.userDomainIsWrong' }));
                            else if (data.status === 'user_domain_not_found')
                                dispatch(logInFailed({ error: 'loginPage.ldap.userDomainNottFound' }));
                            else if (data.status === "multi_domain_not_assign")
                                dispatch(logInFailed({ error: 'loginPage.multi_domain_not_assign' }));
                            else
                                dispatch(logInFailed({ error: 'loginPage.error' }));
                        },
                        dispatch,
                        () => {
                            dispatch(logInFailed({ error: 'loginPage.error' }));
                        }
                    );
                    return;
                }
                case LOGGED_IN: {
                    action.payload.user = { ...action.payload.user, isAuthChecked: true };
                    break;
                }

                case LOG_OUT:
                    appFetch(
                        state.app.backEnd + '/api/login/logout',
                        {
                            credentials: 'include'
                        },
                        (data) => {

                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'navBar.logoutError' }))
                        }
                    )

                    return;
                case LNG_CHANGED:
                    if (state.app.settings.lng !== action.payload.lng)
                        i18n.changeLanguage(action.payload.lng);
                    else
                        return;

                    break;
            }

            return next(action);
        }
    }
}