import React from "react";
import SyncIcon from '@mui/icons-material/Sync';
import Action from "../../../../Action";
import { connectorSync } from "../actions";
import { useDispatch } from "react-redux";
function Sync({ connector }) {
    const dispatch = useDispatch()
    return (
        <Action
            title={'settings.ldap.connectors.sync'}
            icon={<SyncIcon />}
            onClickHandle={
                () => {
                    dispatch(
                        connectorSync({ id: connector._id })
                    )
                }
            }
        />
    )
}
export default Sync