import React from 'react';
import { useSelector } from 'react-redux';
import pages from './pages';
import Meets from './meets/Meets';
import Orgs from './users/orgs/Orgs';
import Profile from './profile/Profile';
import LicensesTab from './licenses/LicensesTab';
import NoLicense from './licenses/NoLicense';
import { Box } from '@mui/material';
import Settings from './settings/Settings';

function Content() {
    const { _selectedMenu, _isLicenseLoaded } = useSelector(state => {
        return {
            _selectedMenu: state.main.selectedMenu,
            _isLicenseLoaded: state.app.settings.isLicenseLoaded,
        }
    })
    const notShowNoLicensePages = [pages.PROFILE];
    if (!_isLicenseLoaded && !notShowNoLicensePages.includes(_selectedMenu)) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><NoLicense /></Box>
    }

    switch (_selectedMenu) {
        case pages.SETTINGS:
            return <Settings />
        case pages.ADMIN_LICENSES:
            return <LicensesTab />

        case pages.ADMIN_USERS:
            return <Orgs />

        case pages.PROFILE:
            return <Profile />

        default:
            return <Meets />
    }
}
export default (Content);
