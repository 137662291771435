import { Box } from "@mui/material";
import React from "react";
import Action from "../../../Action";
import { useDispatch } from "react-redux";
import { showModal } from "../../../actions";
import Form from "./Form";
import { generateFile, sendUploadFile, sendUploadFileClear } from "../actions";
import { useTranslation } from "react-i18next";
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
function MenuLicenses() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return <>
        <Box className="menuLicenses" >
            <Action
                title={'licenses.uploadLicenses'}
                icon={<AddCircleTwoToneIcon fontSize="large" />}
                onClickHandle={
                    () => {
                        dispatch(
                            showModal({
                                title: 'licenses.uploadLicenses',
                                form: <Form />,
                                actionButton: 'licenses.uploadBtn',
                                action: sendUploadFile(),
                                closeAction: { action: sendUploadFileClear() }
                            })
                        )
                    }
                }
            />
            <Action
                title={t('licenses.generateLicenses')}
                icon={<InfoTwoToneIcon fontSize="large" />}
                onClickHandle={
                    () => {
                        dispatch(generateFile())
                    }
                } />




        </Box>
    </>
}
export default MenuLicenses
