import React from "react"
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { baseStyle, acceptStyle, activeStyle, rejectStyle } from "../../const";
import { useEffect } from "react";
import { Box, Input } from "@mui/material";
function Dropzone({ fileHandle }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);
    useEffect(() => {
        acceptedFiles.forEach(file => {
            dispatch(fileHandle(file));
        });
    }, [acceptedFiles])
    return (
        <Box {...getRootProps({ style })} sx={{ width: '20%' }}  >
            <Input {...getInputProps()} />
            {acceptedFiles.length !== 0 ? <Box>{t('licenses.downloadFileDrop', { fileName: acceptedFiles[0]['name'] })}</Box> : <Box>{t('licenses.uploadFileDrop')}</Box>}
        </Box>
    );
}
export default Dropzone