const DRAWER_TOGGLED = 'DRAWER_TOGGLED';
const MENU_CHANGED = 'MENU_CHANGED';
const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
const SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG';
const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG';
const SHOW_MODAL = 'SHOW_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const SHOW_MODAL_PREVIEW = 'SHOW_MODAL_PREVIEW';
const CLOSE_MODAL_PREVIEW = 'CLOSE_MODAL_PREVIEW';
export {
    DRAWER_TOGGLED,
    MENU_CHANGED,
    SHOW_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SHOW_CONFIRM_DIALOG,
    CLOSE_CONFIRM_DIALOG,
    SHOW_MODAL,
    CLOSE_MODAL,
    SHOW_MODAL_PREVIEW,
    CLOSE_MODAL_PREVIEW
}
