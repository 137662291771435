export function ldapURLsStringToArray(proto, string) {
    const inputTrim = string.trim().replaceAll(' ', '');
    const inputSplit = inputTrim.split(',');
    const inputFilter = inputSplit.filter(url => url.length);
    return inputFilter.map(url => `${proto.toLowerCase()}://${url}`);
}

export function ldapURLsArrayToString(proto, array) {
    const URLs = array.map(url => url.replaceAll(`${proto.toLowerCase()}://`, ''));
    return URLs.join(',');
}

export function splitUserPrincipalName(userPrincipalName = '') {
    const buffer = userPrincipalName.split('@');

    if (buffer.length === 2) {
        return {
            sAMAccountName: buffer[0],
            domain: buffer[1]
        };
    }

    return {
        sAMAccountName: userPrincipalName,
        domain: ''
    };
};
