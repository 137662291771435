import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    Box,
    CssBaseline,
    Container,
    Card
} from '@mui/material';
import Logo from './Logo';

function MeetPage(props) {
    const { _locationHost, _meet } = useSelector(state => {
        return {
            _locationHost: state.app.locationHost,
            _meet: state.meet
        }
    });

    useEffect(() => {
        const externalApi = document.createElement('script');
        externalApi.src = props._externalApi;
        document.body.appendChild(externalApi);
    }, []);

    function getPrejoinStyles() {
        return _meet.info?.custom
            ? { backgroundColor: _meet.info.custom.prejoinBgColor || null,
                backgroundImage: _meet.info.custom.prejoinBgImage
                    ? `url(https://${_locationHost}${_meet.info.custom.prejoinBgImage})`
                    : null,
                backgroundSize: _meet.info.custom.prejoinBgImage
                    ? 'cover'
                    : null }
            : null;
    }

    function getPrejoinInfoStyles() {
        return _meet.info?.custom
        ? {
            backgroundColor: _meet.info.custom.prejoinInfoBgColor || null,
            color: _meet.info.custom.prejoinInfoFontColor || null }
        : null;
    }

    return (
        <Box className="meetPage" style={getPrejoinStyles()}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Logo />
                <Card style={getPrejoinInfoStyles()}>
                    <Box className="info">
                        {props.children}
                    </Box>
                </Card>
            </Container>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        _externalApi: state.app.externalApi
    }
}

export default connect(mapStateToProps)(MeetPage);
