import React from 'react';
import { useDispatch } from 'react-redux';
import { drawerToggled } from '../actions';
import AdminMenu from './AdminMenu';
import MeetsFilterMenu from './MeetsFilterMenu';
import {
  Drawer,
  Box,
  IconButton,
  Divider
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
function SideBar({ isDrawerOpen }) {
  const dispatch = useDispatch()
  return (
    <Drawer
      className="sideBar"
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      classes={{
        paper: "drawerPaper"
      }}
    >
      <Box className="drawerHeader">
        <IconButton onClick={() => dispatch(drawerToggled())}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Divider />
      <AdminMenu />
      <MeetsFilterMenu />
    </Drawer>
  )
}
export default (SideBar);
