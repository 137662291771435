import {
    isIOS,
    isAndroid,
    isElectron,
    isFirefox,
    isChromium,
    isChrome,
    isYandex,
    isOpera,
    isEdgeChromium,
    isSafari,
    browserVersion
} from 'react-device-detect';


export function isBrowserOk() {
    if(isIOS || isAndroid || isElectron)
        return true;

    if(isChromium || isChrome || isEdgeChromium)
        return true;
    
    if(isYandex && browserVersion >= 20)
        return true;

    if(isOpera && browserVersion >= 68)
        return true;

    if(isFirefox || isSafari)
        return true;

    return false;
}


export function isBrowserWarning() {
    if(isIOS || isAndroid)
        return false;

    if(isFirefox || isSafari)
        return true;

    return false;
}


export function isMobile() {
    if(isIOS || isAndroid)
        return true;

    return false;
}
