import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import mintLogo from '../../../../../../../css/images/mint_logo.svg'
import izumLogo from '../../../../../../../css/images/izum_logo.svg'
function Logo({ previewLogo }) {
    const { _isIzum } = useSelector(state => {
        return {
            _isIzum: state.app.isIzum
        }
    })


    return (
        previewLogo ? <Box className={'previewLogo'} src={`${previewLogo}`} component="img"></Box> : !previewLogo && _isIzum ? <Box className={`meetLogo ${_isIzum ? 'izum' : ''}`} src={_isIzum && izumLogo} component="img"></Box> : null
    )

}

export default Logo;