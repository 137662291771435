import React from 'react';
import { copyToClipboard } from '../../../../../app/functions/copyToClipboard';
import { showNotification } from '../../../../actions';
import Action from '../../../../Action';
import { PublicTwoTone } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

function CopyWebinarLink({ meet, webinarURL }) {
    const dispatch = useDispatch()
    return (
        <Action
            disabled={!meet.webinar}
            title={'meetsList.copyToClipboardWebinarLink'}
            icon={<PublicTwoTone />}
            onClickHandle={
                () => {
                    copyToClipboard(webinarURL)
                        .then(() => {
                            dispatch(showNotification({ severity: 'success', text: 'meetsList.linkCopied' }));
                        })
                        .catch(error => {
                            console.error(error);
                            dispatch(showNotification({ severity: 'error', text: 'meetsList.linkCopyFailed' }));
                        })
                }
            }
        >
        </Action>
    )
}

export default CopyWebinarLink;
