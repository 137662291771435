import {
    ARCHIVE_DELETE_FILE,
    ARCHIVE_DOWNLOAD_FILE
} from './actionTypes';
import { appFetch } from '../../../../../app/functions';
import { copyToClipboard } from '../../../../../app/functions/copyToClipboard';
import { showNotification } from '../../../../actions';
import { closeConfirmDialog } from '../../../../actions';
import { archiveSetMeet } from './actions';


export const archiveMiddleware = ({ getState, dispatch }) => next => action => {
    const state = getState();

    switch( action.type ) {
        case ARCHIVE_DELETE_FILE:
            appFetch(
                state.app.backEnd + '/api/files',
                {
                    credentials: 'include',
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        fileType: action.payload.fileType,
                        meetId: action.payload.meetId,
                        file: action.payload.file,
                        fileIndex: action.payload.fileIndex
                    })
                },
                (data) => {
                    if( data.status === 'ok' ) {
                        const meet =  { ...state.archive.meet };
                        meet[action.payload.fileType].splice(action.payload.fileIndex, 1);
                        dispatch( archiveSetMeet(meet) );
                        dispatch( showNotification({ severity: 'success', text: 'archive.deleteSucceeded' }) );
                    } else
                        dispatch( showNotification({ severity: 'error', text: 'archive.deleteFailed' }) );

                    dispatch( closeConfirmDialog() );
                },
                dispatch,
                () => {
                    dispatch( showNotification({ severity: 'error', text: 'archive.deleteFailed' }) );
                }
            );

            return;


        case ARCHIVE_DOWNLOAD_FILE:
            const errorText = action.payload.copyToClipboard ? 'copyLinkFailed' : 'downloadFailed';

            appFetch(
                state.app.backEnd + '/api/files',
                {
                    credentials: 'include',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        fileType: action.payload.fileType,
                        meetId: action.payload.meetId,
                        file: action.payload.file,
                        fileIndex: action.payload.fileIndex
                    })
                },
                (data) => {
                    if( data.status === 'ok' && data.link ) {
                        if( action.payload.copyToClipboard ) {
                            copyToClipboard(`${state.app.urlBase}${data.link}`)
                            .then(() => {
                                dispatch( showNotification({ severity: 'success', text: 'archive.copyLinkSucceeded' }) );  
                            })
                            .catch(error => {
                                console.error(error);
                                dispatch( showNotification({ severity: 'error', text: `archive.${ errorText }` }) );
                            })
                        } else {
                            window.open( data.link );
                        }
                    } else
                        dispatch( showNotification({ severity: 'error', text: `archive.${ errorText }` }) );
                },
                dispatch,
                () => {
                    dispatch( showNotification({ severity: 'error', text: `archive.${ errorText }` }) );
                }
            );

            return;
    }

    return next(action);
}
