import React from 'react';
import {
    Tooltip,
    IconButton
} from '@mui/material';

function Option({
    showable = true,
    title,
    enabled = false,
    onClickHandle,
    icon }) {
    if (!showable)
        return null;

    return (
        <Tooltip title={title} arrow>
            <IconButton
                color={enabled ? 'primary' : 'default'}
                onClick={onClickHandle}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )

}

export default Option;
