import { Box, Card, Container, CssBaseline, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../previews/previewComponents/Logo";
import { prettyDateTime } from "../../../../../../PrettyDateTime";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "../../../../../../loader/Loader";
function PreviewImage({ t, org }) {
    const {
        _locationHost,
        _opacityBg,
        _prejoinBgColor,
        _prejoinBgImage,
        _prejoinInfoBgColor,
        _prejoinInfoFontColor,
        _prejoinLogo,
        _selectColor
    } = useSelector(state => {
        return {
            _locationHost: state.app.locationHost,
            _opacityBg: state.users.paletteSettings.opacityBg,
            _prejoinBgColor: state.users.paletteSettings.prejoinBgColor,
            _prejoinBgImage: state.users.paletteSettings.prejoinBgImage,
            _prejoinInfoBgColor: state.users.paletteSettings.prejoinInfoBgColor,
            _prejoinInfoFontColor: state.users.paletteSettings.prejoinInfoFontColor,
            _prejoinLogo: state.users.paletteSettings.prejoinLogo,
            _selectColor: state.users.paletteSettings.selectColor
        }
    });

    const locationUrl = `https://${_locationHost}`;

    const backgroundColorInfo = {
        backgroundColor: `${_prejoinInfoBgColor}`,
        color: `${_prejoinInfoFontColor}`
    }

    const [imgIsLoading, setImgIsLoading] = useState(true)
    const handleImageLoaded = () => setImgIsLoading(false);
    const prejoinBgImageUrl = _prejoinBgImage.url && `${locationUrl}${_prejoinBgImage.url}`
    const backgroundImage = _selectColor && {
        backgroundImage: `url(${_prejoinBgImage.image || prejoinBgImageUrl}) `,
        opacity: `${_opacityBg ? 0.5 : 1}`
    }
    const backgroundColor = {
        backgroundColor: `${_prejoinBgColor} `
    }
    const textColor = {
        color: `${_prejoinInfoFontColor ? _prejoinInfoFontColor : _prejoinInfoFontColor ? _prejoinInfoFontColor : null} `,
    }

    useEffect(() => {
        if (_prejoinBgImage.image || prejoinBgImageUrl) {
            const image = new Image();
            image.onload = handleImageLoaded;
            image.src = _prejoinBgImage.image || prejoinBgImageUrl ? _prejoinBgImage.image || prejoinBgImageUrl : undefined;
        }


    }, [imgIsLoading, _prejoinBgImage]);

    return (
        <>
            <Box className={`meetPage previewImage ${_prejoinBgImage && _opacityBg ? 'opacityBg' : ''}`}
            >
                {_prejoinBgImage.image || prejoinBgImageUrl ?
                    imgIsLoading ? <Loader preloader={imgIsLoading} /> : <Box className='meetPageBg' style={backgroundImage} ></Box> : <Box className='meetPageBg' style={backgroundColor} ></Box>}
                <CssBaseline />
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Logo previewLogo={_prejoinLogo.image || _prejoinLogo.url ? _prejoinLogo.image || `${locationUrl}${_prejoinLogo.url}` : null} />
                    <Card style={backgroundColorInfo}>
                        <Box className="info" style={textColor}>
                            <Typography>
                                {` ${t('meet.description')}: `}
                                <i>{t('orgs.paletteSettings.previewThemeTitle')}</i>
                            </Typography>
                            <br />
                            <Typography>
                                {` ${t('meet.datetime')}: `}
                                <b>{prettyDateTime(new Date())}</b>
                            </Typography>
                            <Typography>
                                {` ${t('meet.duration')}: `}
                                <b>
                                    {` 1 ${t('meetForm.duration.hours')} `}
                                    {` 0 ${t('meetForm.duration.minutes')} `}
                                </b>
                            </Typography>
                            <br />

                            {`${t('meet.showLink.message1')} `}
                            <a href="#" style={_prejoinInfoFontColor ? textColor : null}>
                                {t('meet.showLink.message2')}
                            </a>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    )
}
export default withTranslation()(PreviewImage)