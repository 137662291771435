import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { meetsFilterItemSelected } from './actions';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useAutocomplete
} from '@mui/material';
import { isPastMeets } from '../functions';
import { meetsVisibleRecords } from '../actions';


function Types({ t, isRooms }) {
    const dispatch = useDispatch();
    const { _selectedTypes, _isPastMeets } = useSelector(state => {
        return {
            _selectedTypes: state.meetsFilter.types,
            _isPastMeets: isPastMeets(state.meets.filterCategory)
        }
    })
    function renderTypesItems() {
        const typesItems = [];
        typesItems.push(
            <MenuItem
                key={'meet'}
                value={'meet'}
            >
                {t('meetsFilter.types.meet')}
            </MenuItem>
        );

        typesItems.push(
            <MenuItem
                key={'periodic'}
                value={'periodic'}
            >
                {t('meetsFilter.types.periodic')}
            </MenuItem>
        );

        if (_isPastMeets) {
            typesItems.push(
                <MenuItem
                    key={'room'}
                    value={'room'}
                >
                    {t('meetsFilter.types.room')}
                </MenuItem>
            );
        }

        return typesItems;
    }

    if (isRooms)
        return null;

    const selectedTypes = _isPastMeets ? _selectedTypes : _selectedTypes.filter(type => type !== 'room');

    return (
        <FormControl
            variant="outlined"
            size="small"
            margin="dense"
            className="select"
        >
            <InputLabel id="types">{t('meetsFilter.types.type')}</InputLabel>
            <Select
                label={t('meetsFilter.types.type')}
                labelId="types"
                multiple
                name="types"
                value={selectedTypes}
                onChange={event => {
                    dispatch(meetsFilterItemSelected(event.target.name, event.target.value))
                    dispatch(meetsVisibleRecords());
                }}
            >
                {renderTypesItems()}
            </Select>
        </FormControl>
    )
}



export default withTranslation()(Types);
