import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import Items from './Items';

function Licenses({ t, license }) {
    const { isPoolLicense, ts } = useSelector(state => {
        return {
            isPoolLicense: state.app.settings.isPoolLicense,
            ts: state.app.settings.ts
        }

    });

    return (
        <Box className="licenses">
            <TableContainer component={Paper}>
                <Table aria-label="licensesTable">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t('licenses.id')}</TableCell>
                            <TableCell align="center">{t('licenses.license')}</TableCell>
                            <TableCell align="right" className="participants">{t('licenses.participants')}</TableCell>
                            {
                                isPoolLicense
                                    ?
                                    null
                                    :
                                    <TableCell align="right" className="rooms">{t('licenses.rooms')}</TableCell>
                            }
                            <TableCell align="right" className="startTime">{t('licenses.startTime')}</TableCell>
                            <TableCell align="right" className="endTime">{t('licenses.endTime')}</TableCell>
                            {
                                isPoolLicense
                                    ?
                                    null
                                    :
                                    <TableCell align="center" className="used">{t('licenses.used')}</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Items isPoolLicense={isPoolLicense} licenses={license} ts={ts} />
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default (withTranslation()(Licenses));
