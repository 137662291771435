
const genetarSettings = ['Настройка 1', 'Настройка 2', 'Настройка 3'];

const TEST_STATUSES = {
    SUCCESS: 'success',
    ERROR: 'error',
    PENDING: 'pending',
    UNKNOWN: 'unknown'
}
const LDAP_SYNC_STATUSES = {
    UNKNOWN: 'unknown',
    PENDING: 'pending',
    ERROR: 'error',
    SUCCESS: 'success'
};
export {
    genetarSettings,
    TEST_STATUSES,
    LDAP_SYNC_STATUSES
}