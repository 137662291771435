import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { roomJoin, roomStartMobileIframe } from './actions';
import {
    Container,
    TextField,
    Button
} from '@mui/material';
import InputMask from 'react-input-mask';
import { isIOS } from 'react-device-detect';
import { useState } from 'react';



function JoinRoom({ roomId, hash, t }) {
    const [stateRoomId, setStateRoomId] = useState(roomId)
    const dispatch = useDispatch()
    const { _room, _settings } = useSelector(state => {
        return {
            _room: state.room,
            _settings: state.app.settings
        }
    })
    function joinRoom(event) {
        event.preventDefault();
        dispatch(roomJoin(stateRoomId, hash));
    }

    const { info, status, limit } = _room;

    return (
        <Container maxWidth="sm">
            <form onSubmit={event => { joinRoom(event) }}>
                <InputMask
                    mask="99-99-99"
                    disabled={Boolean(roomId) && Boolean(hash)}
                    value={stateRoomId}
                    onChange={
                        event => {
                            setStateRoomId(event.target.value)
                        }
                    }
                >
                    {
                        () =>
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="dense"
                                fullWidth
                                required
                                label={t('room.roomId')}
                                name="roomId"
                                disabled={Boolean(roomId) && Boolean(hash)}
                                error={Boolean(status)}
                                helperText={Boolean(status) ? t(`room.${status}`, { limit }) : ''}
                            />
                    }
                </InputMask>

                <br />
                <br />

                <Button variant="contained" type="submit">{t('room.join')}</Button>
            </form>

            {Object.keys(info).length > 0 ?
                <p>
                    {`${t('meet.mobile.loadFrom')} `}
                    {
                        isIOS
                            ?
                            <a href={_settings.iosApp} target="_blank">
                                App Store
                            </a>
                            :
                            <a href={_settings.androidApp} target="_blank">
                                Google Play
                            </a>
                    }

                    <br />
                    {`${t('meet.mobile.message1')}? `}
                    <a
                        href="#"
                        onClick={
                            event => {
                                event.preventDefault();
                                dispatch(roomStartMobileIframe());
                            }
                        }
                    >
                        {t('meet.mobile.message2')}
                    </a>
                </p>
                :
                null
            }
        </Container>
    )
}

export default withTranslation()(JoinRoom);
