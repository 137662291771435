import React from 'react';
import { useDispatch } from 'react-redux';
import { Edit as EditIcon } from '@mui/icons-material';
import Form from '../../LDAP/Form';
import { closeModal, showModal } from '../../../../actions';
import Action from '../../../../Action';
import { connectorEdit, settingsFormClear } from '../actions';

function Edit({ connector }) {
    const dispatch = useDispatch();
    return (
        <Action
            title={'settings.ldap.connectors.editConnector'}
            icon={<EditIcon />}
            onClickHandle={
                () => {
                    dispatch(
                        showModal({
                            title: 'settings.ldap.connectors.editConnector',
                            form: <Form connector={connector} />,
                            actionButton: 'settings.ldap.form.save',
                            action: connectorEdit({ id: connector._id }),
                            closeAction: { action: closeModal({ action: settingsFormClear() }) },
                            sizeDialog: 'sm',
                        })
                    )
                }
            }
        />
    )

}
export default Edit;