import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { logOut } from '../../app/actions';
import { menuChanged } from '../actions';
import pages from '../content/pages';
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon
} from '@mui/material';
import {
    AccountCircle,
    ExitToApp
} from '@mui/icons-material';
import { useState } from 'react';


function AccountButton({ t }) {
    const [accountAnchor, setAccountAnchor] = useState(null)
    const { _user } = useSelector(state => {
        return {
            _user: state.app.user
        }
    })
    const handlerProfile = (value) => {
        setAccountAnchor(value);
    }
    const dispatch = useDispatch()
    return (
        <Box>
            <Button
                className="profile"
                onClick={() =>
                    dispatch(menuChanged({ selectedMenu: pages.PROFILE }))}
            >
                {_user.name}
            </Button>
            <IconButton
                aria-label="account of current user"
                aria-controls="menuAccount"
                aria-haspopup="true"
                onClick={event => {
                    handlerProfile(event.currentTarget)
                }}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menuAccount"
                anchorEl={accountAnchor}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(accountAnchor)}
                onClose={() => {
                    setAccountAnchor(null)
                }}
            >
                <MenuItem
                    onClick={() => dispatch(logOut())}
                >
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    {t('navBar.logout')}
                </MenuItem>
            </Menu>
        </Box>
    )

}
export default (withTranslation()(AccountButton));
