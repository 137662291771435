import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { closeNotification } from './actions';
import {
    Snackbar,
    Alert
} from '@mui/material';


function Notification({ t }) {
    const { _notification } = useSelector(state => {
        return {
            _notification: state.main.notification
        }
    })
    const dispatch = useDispatch()
    function closeAlert(event, reason) {
        if (reason === 'clickaway')
            return;
        dispatch(closeNotification());
    }

    return (
        <Snackbar
            open={_notification.isOpen}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            autoHideDuration={5000}
            onClose={(event, reason) => { closeAlert(event, reason) }}
        >
            <Alert
                severity={_notification.severity}
                onClose={(event, reason) => { closeAlert(event, reason) }}
            >
                {t(_notification.text, _notification.textAttr)}
            </Alert>
        </Snackbar>
    )

}



export default withTranslation()(Notification);
