import React from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dashboardColorPickerApp } from "../../../actions";
import AbstractColorPicker from "./AbstractColorPicker";
import { useEffect } from "react";

function ColorPickerApplication({ t, org, label, icon }) {
    const { _appBgColor } = useSelector(state => {
        return {
            _appBgColor: state.users.paletteSettings.appBgColor
        }
    })
    const dispatch = useDispatch();
    const [colorApplication, setColorApplication] = useState({
        displayColorPicker: false,
        color: _appBgColor ? _appBgColor : 'rgba(60,58,66,1)'
    })
    useEffect(() => {
        setColorApplication({ ...colorApplication, color: _appBgColor })
    }, [_appBgColor])

    const handleClick = () => {
        setColorApplication({ ...colorApplication, displayColorPicker: !colorApplication.displayColorPicker })
        dispatch(dashboardColorPickerApp(colorApplication.color))

    };

    const handleChange = (color) => {
        setColorApplication({ ...colorApplication, color: `rgba(${(Object.values(color.rgb)).join()})` });
        dispatch(dashboardColorPickerApp(`rgba(${(Object.values(color.rgb)).join()})`))
    }
    return (
        <AbstractColorPicker handleClickPicker={handleClick} handleChangePicker={handleChange} colorStyle={colorApplication} label={label} icon={icon} />
    )
}

export default withTranslation()(ColorPickerApplication)  