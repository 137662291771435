import React, { useEffect, useState } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userRolesList, usersFormFieldChanged } from "../actions";


function SelectItems({ t, org, orgId, user, defaultName }) {
    const { _userRoles, _form } = useSelector((state) => {
        return {
            _userRoles: state.app.userRoles,
            _form: state.users.form,
        }
    });

    const dispatch = useDispatch()
    const selectMenuItems = [
        org?.name == 'default' || defaultName ? t('users.role.defaultOrg.adminServer') : t('users.role.org.adminOrgs'),
        t('users.role.user'),
        t('users.role.limitedUser'),

    ];
    const [selectItems, setSelectItems] = useState([]);
    useEffect(() => {
        user && dispatch(userRolesList(user.role))
    }, [])
    useEffect(() => {
        let userRolesArray = [];
        if (_userRoles.length !== 0) {
            _userRoles.forEach((element, index) => {
                userRolesArray.push({
                    value: element,
                    name: selectMenuItems[index]

                })

            });
            setSelectItems(userRolesArray);
        }

    }, [_userRoles])

    const handleChange = (event) => {
        dispatch(userRolesList(event.target.value));
        dispatch(usersFormFieldChanged({ field: event.target.name, value: event.target.value }))
    };

    return (
        <>
            <FormControl
                sx={{ 'width': '50%', mr: 2 }}
                margin="dense"
            >
                <InputLabel id="demo-simple-select-label">{t('users.role.chooseRole')}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('users.role.chooseRole')}
                    onChange={handleChange}
                    value={_form.role}
                    name="role"
                >
                    {
                        selectItems.map((item, index) => <MenuItem value={item.value} key={`${item}_${index}`}> {item.name}</MenuItem>)
                    }

                </Select>
            </FormControl>
        </>
    )
}
export default withTranslation()(SelectItems)