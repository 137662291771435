import {
    ARCHIVE_SET_MEET,
    ARCHIVE_DELETE_FILE,
    ARCHIVE_DOWNLOAD_FILE
} from './actionTypes';


export function archiveSetMeet(meet) {
    return {
        type: ARCHIVE_SET_MEET,
        payload: {
            meet
        }
    }
}


export function archiveDeleteFile(fileType, meetId, file, fileIndex) {
    return {
        type: ARCHIVE_DELETE_FILE,
        payload: {
            fileType,
            meetId,
            file,
            fileIndex
        }
    }
}


export function archiveDownloadFile(fileType, meetId, file, fileIndex, copyToClipboard = false) {
    return {
        type: ARCHIVE_DOWNLOAD_FILE,
        payload: {
            fileType,
            meetId,
            file,
            fileIndex,
            copyToClipboard
        }
    }
}
