import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardColorPickerText } from "../../../actions";
import { useState } from "react";
import AbstractColorPicker from "./AbstractColorPicker";

function TextColorPicker({ label, icon }) {
    const { _prejoinInfoFontColor } = useSelector(state => {
        return {
            _prejoinInfoFontColor: state.users.paletteSettings.prejoinInfoFontColor
        }
    });
    const dispatch = useDispatch();
    const [textColor, setTextColor] = useState({
        displayColorPicker: false,
        color: `${_prejoinInfoFontColor ? _prejoinInfoFontColor : 'rgba(0,0,0,0.87)'}`
    })
    useEffect(() => {
        setTextColor({ ...textColor, color: _prejoinInfoFontColor })
    }, [_prejoinInfoFontColor])

    const handleClickTextColor = () => {
        setTextColor({ ...textColor, displayColorPicker: !textColor.displayColorPicker })
        dispatch(dashboardColorPickerText(textColor.color))
    };

    const handleChangeTextColor = (color) => {
        setTextColor({ ...textColor, color: `rgba(${(Object.values(color.rgb)).join()})` });
        dispatch(dashboardColorPickerText(`rgba(${(Object.values(color.rgb)).join()})`))
    };
    return (
        <AbstractColorPicker handleClickPicker={handleClickTextColor} handleChangePicker={handleChangeTextColor} colorStyle={textColor} label={label} icon={icon} />
    )
}
export default TextColorPicker