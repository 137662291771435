import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Tab,
    Box
} from '@mui/material';
import SettiingsLoginPage from "../SettiingsLoginPage";
import SettiingsApplication from "../SettiingsApplication";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { appBgImageAsPrejoin, customLoadNewState, selectColor, selectColorApp } from '../../../actions';


function FormPalette({ t, org }) {
    const dispatch = useDispatch()
    const tabsObject = [
        { label: t('orgs.paletteSettings.titleLoginPage'), content: <SettiingsLoginPage org={org} /> },
        { label: t('orgs.paletteSettings.titleApplication'), content: <SettiingsApplication org={org} /> },
    ]
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (org && org._id) {
            dispatch(customLoadNewState(
                { ...org.custom }
            ));
            if (org.custom) {
                org.custom.prejoinBgImage ? dispatch(selectColor(true)) : dispatch(selectColor(false));
            }
            if (org.custom) {
                dispatch(appBgImageAsPrejoin(org.custom.appBgImageAsPrejoin))
            }
        }

    }, [org])

    useEffect(() => {
        if (org && org._id) {
            if (org.custom) {
                org.custom.appBgImage ? dispatch(selectColorApp(true)) : dispatch(selectColorApp(false))
            }
        }
    }, [org])

    return (
        <>

            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} >
                        {tabsObject.map((item, index) => {
                            return <Tab label={item.label} value={(index + 1).toString()} key={`tab_${index}`} />
                        })}
                    </TabList>
                </Box>
                {tabsObject.map((item, index) => {
                    return <TabPanel value={(index + 1).toString()} key={`tab_${index}`}>{item.content}</TabPanel>
                })}
            </TabContext>
        </>
    )
}




export default withTranslation()(FormPalette);
