import { ROOM_JOIN, ROOM_START_MOBILE_IFRAME } from './actionTypes';
import meetStatuses from '../meet/meetStatuses';


const roomState = {
    info: {},
    status: null,
    limit: 0
};


export function roomReducer(state = roomState, action) {
    switch( action.type ) {
        case ROOM_JOIN:
            return {
                ...state,
                info: action.info !== undefined ? action.info : roomState.info,
                status: action.status,
                limit: action.limit !== undefined ? action.limit : roomState.limit
            };

        case ROOM_START_MOBILE_IFRAME:
            return {
                ...state,
                status: meetStatuses.STARTED
            };

        default:
            return state;
    }
}
