import { Chip, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from './actions/Actions';
import { prettyDateTime } from '../../../../PrettyDateTime';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CircularProgress from '@mui/material/CircularProgress';
import { reloadSyncConnectors } from './actions';
import useInterval from '../../../../hooks/hooks';

function Items() {
    function updateList(background = false) {
        dispatch(reloadSyncConnectors({ background }));
    }
    const { _connectors, _updateMeetsListInterval } = useSelector((state => {
        return {
            _connectors: state.settings.connectors,
            _updateMeetsListInterval: state.app.settings.updateMeetsListInterval
        }
    }));
    const dispatch = useDispatch();
    useInterval(() => {
        updateList(true)
    }, _updateMeetsListInterval);
    const { t } = useTranslation();
    if (Array.isArray(_connectors) && _connectors.length > 0)
        return _connectors.map(
            connector => {
                return (
                    <TableRow key={connector._id} >
                        <TableCell align="center" sx={{ padding: "1rem 0.5rem" }}>
                            {connector.name}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "1rem 0.5rem" }}>
                            {connector.domain}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "1rem 0.5rem" }}>
                            {connector.baseDN}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "1rem 0.5rem" }}>
                            {connector.sync.lastSyncTime === 0 ? t("settings.ldap.connectors.lastSyncTime") : prettyDateTime(connector.sync.lastSyncTime)}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "1rem 0.5rem" }}>
                            <Chip label={t("settings.ldap.connectors.ldaPSyncStatuses." + connector.sync.lastSyncStatus)} sx={{ flexDirection: "row-reverse", p: '0 1rem' }}
                                icon={connector.sync.lastSyncStatus == 'success' ? <CheckIcon size="1rem" /> : connector.sync.lastSyncStatus == 'error' ? <PriorityHighIcon size="1rem" /> : connector.sync.lastSyncStatus == 'pending' ? <CircularProgress size="1rem" /> : null}
                                color={connector.sync.lastSyncStatus == 'success' ? "success" : connector.sync.lastSyncStatus == 'pending' ? "warning" : connector.sync.lastSyncStatus == 'error' ? "error" : "default"} variant="outlined" />
                        </TableCell>
                        <TableCell align="right" sx={{ padding: "1rem 0.5rem" }}>
                            <Actions connector={connector} />
                        </TableCell>
                    </TableRow>
                )
            }
        )

    return (
        <TableRow  >
            <TableCell > {t('orgs.empty')}</TableCell>
        </TableRow>
    )

}

export default Items;