import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    meetEdit,
    meetEditToggled
} from './actions';
import {
    Container,
    Card,
    CardHeader,
    Divider,
    Box,
    Button
} from '@mui/material';
import Form from './form/Form';


function Edit({ t, isRooms, isWebinars, updateList }) {
    const dispatch = useDispatch();
    function onEditSubmit(event) {
        event.preventDefault();
        dispatch(meetEdit());
    }
    const { _isCurrent } = useSelector(state => {
        const editId = state.meets.editId;
        return {
            _isCurrent: state.meets.current.includes(editId)
        }
    })

    return (
        <Container maxWidth="md">
            <Card className="editMeet">
                <CardHeader title={`${t('editMeet.title')}${_isCurrent ? ' (' + t('editMeet.current') + ')' : ''}`} />

                <Divider light />

                <Form
                    isRooms={isRooms}
                    isWebinars={isWebinars}
                    optionsExpanded={true}
                    submitBtnText={t('editMeet.save')}
                    onFormSubmit={onEditSubmit}
                />

                <Box className="back">
                    <Button
                        onClick={
                            () => {
                                updateList();
                                dispatch(meetEditToggled({ editId: null }));
                            }
                        }
                    >
                        {t('editMeet.back')}
                    </Button>
                </Box>
            </Card>
        </Container>
    )
}



export default withTranslation()(Edit);
