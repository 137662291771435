import React from "react";
import Edit from "./Edit";
import Delete from "./Delete";
import Sync from "./Sync";


function Actions({ connector }) {
    return <>
        <Sync connector={connector} />
        <Edit connector={connector} />
        <Delete connector={connector} />
    </>
}
export default Actions