import {
	ORGS_LOAD,
	ORGS_FORM_FIELD_CHANGED,
	ORGS_FORM_EDIT,
	ORG_ADD,
	ORG_DEL,
	ORG_EDIT,
	USERS_FORM_FIELD_CHANGED,
	USER_ADD,
	USER_DEL,
	USER_EDIT,
	CUSTOM_THEME_ADD,
	USERS_FORM_FIELD_CHANGED_PASSWORD,
	PASSWORD_CHECK,

} from './actionTypes';

import {
	orgsLoad,
	orgsLoaded,
	orgsFormClear,
	usersFormClear,
	orgsFormFieldChanged,
	changePasswordFormClear,
	errorMessage,

} from './actions';

import {
	licensesLoad
} from '../licenses/actions';

import {
	showNotification,
	closeConfirmDialog,
	closeModal
} from '../../actions';

import {
	appFetch
} from '../../../app/functions';

import {
	prettyDateTime
} from '../../../PrettyDateTime';
import { USER_PERMS } from '../../../const';


export function usersMiddleware({ getState, dispatch }) {
	return function (next) {
		return function (action) {
			const state = getState();

			switch (action.type) {
				case ORGS_LOAD:
					const userPermsLicenses = state.app.user.perms.includes(USER_PERMS.LICENSES);
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include'
						},
						(data) => {
							if (data.status === 'ok' && data.orgs) {
								dispatch(
									orgsLoaded({
										orgs: data.orgs
									})
								);
								if (userPermsLicenses)
									dispatch(licensesLoad());

							}
							else
								dispatch(showNotification({ severity: 'error', text: 'orgs.loadError' }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.loadError' }));
						}
					);

					return;

				case ORGS_FORM_FIELD_CHANGED:
					switch (action.payload.field) {
						case 'name':
							action.payload.value = action.payload.value.replace(/\s{2,}/, ' ');
							break;

						case 'participants':
							action.payload.value = action.payload.value.replace(/[^\d]/, '');
							break;

						case 'meetsPerTime':
							action.payload.value = parseInt(action.payload.value.replace(/[^\d]/, ''), 10) || '';
							break;
						default:
							break
					}
					break;
				case ORGS_FORM_EDIT:
					action.payload.org.exp = prettyDateTime(action.payload.org.exp).split(' ')[0];
					break;
				case ORG_ADD:
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include',
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								name: state.users.orgsForm.name,
								participants: state.users.orgsForm.participants,
								meetsPerTime: state.users.orgsForm.meetsPerTime,
								licenses: state.users.orgsForm.licenses,
								exp: state.users.orgsForm.exp,
								dedicatedJvb: state.users.orgsForm.dedicatedJvb,
								bindDN: state.users.orgsForm.bindDN,
								domain: state.users.orgsForm.domain,
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.add.succeeded' }));
								dispatch(closeModal({ action: orgsFormClear() }));
								dispatch(orgsLoad());

							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'orgs.add.error' }));

							else {
								if (data.status === 'participants_incorrect')
									dispatch(orgsLoad());
								else if (data.status === 'license_already_used') {
									dispatch(orgsLoad());
									dispatch(orgsFormFieldChanged({ field: 'licenses', value: [] }));
								}
								else if (data.status === "org_domain_unavailable") {
									dispatch(showNotification({ severity: 'error', text: 'orgs.add.' + data.status }));
								}

								dispatch(showNotification({ severity: 'error', text: 'orgs.add.' + data.status }));
							}
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.add.error' }));
						}
					);

					return
				default:
					break

				case ORG_DEL:
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include',
							method: 'DELETE',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.del.succeeded' }));
								dispatch(closeConfirmDialog());
								dispatch(orgsLoad());

							} else
								dispatch(showNotification({ severity: 'error', text: 'orgs.del.error' }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.del.error' }));
						}
					);

					return;


				case ORG_EDIT:
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include',
							method: 'PUT',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id,
								name: state.users.orgsForm.name,
								participants: state.users.orgsForm.participants,
								meetsPerTime: state.users.orgsForm.meetsPerTime,
								licenses: state.users.orgsForm.licenses,
								exp: state.users.orgsForm.exp,
								dedicatedJvb: state.users.orgsForm.dedicatedJvb,
								bindDN: state.users.orgsForm.bindDN,
								domain: state.users.orgsForm.domain,
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.edit.succeeded' }));
								dispatch(closeModal({ action: orgsFormClear() }));
								dispatch(orgsLoad());
								dispatch(errorMessage(false))
							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'orgs.edit.error' }));
							else if (data.status === "org_domain_unavailable") {
								dispatch(showNotification({ severity: 'error', text: 'orgs.add.' + data.status }));
							}
							else if (data.status === "org_domain_user_already_exist") {
								dispatch(showNotification({ severity: 'error', text: 'orgs.edit.' + data.status, textAttr: { domain: state.users.orgsForm.domain } }));
							}
							else if (data.status === "binddn_already_exists") {
								dispatch(showNotification({ severity: 'error', text: 'orgs.edit.' + data.status }));
								dispatch(errorMessage(true))
							}
							else {
								if (data.status === 'participants_incorrect')
									dispatch(orgsLoad());
								else if (data.status === 'license_already_used') {
									dispatch(orgsLoad());
									dispatch(orgsFormFieldChanged({ field: 'licenses', value: action.payload.licenses }));
								}

								dispatch(showNotification({ severity: 'error', text: 'orgs.edit.' + data.status }));
							}
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.edit.error' }));
						}
					);

					return;


				case USERS_FORM_FIELD_CHANGED:
					switch (action.payload.field) {
						case 'username':
							action.payload.value = action.payload.value.replace(/\W/, '');
							break;
						case 'password':
							action.payload.value = action.payload.value.replace(/[^a-z\d!@#$%^&*-]/ig, '')
							break;

						case 'passwordConfirm':
							action.payload.value = action.payload.value.replace(/[^a-z\d!@#$%^&*-]/ig, '')
							break;

						case 'name':
						case 'email':
						case 'phone':
							action.payload.value = action.payload.value.replace(/\s{2,}/g, ' ');
							break;
						case 'role':
							action.payload.value = action.payload.value.replace(/\W/, '');
							break;
						default:
							break
					}

					break;


				case USERS_FORM_FIELD_CHANGED_PASSWORD:
					switch (action.payload.field) {
						case 'newPassword':
							action.payload.value = action.payload.value.replace(/[^a-z\d!@#$%^&*-]/ig, '')
							break;
						case 'newPasswordConfirm':
							action.payload.value = action.payload.value.replace(/[^a-z\d!@#$%^&*-]/ig, '')
							break;
					}

					break;

				case USER_ADD:
					appFetch(
						state.app.backEnd + '/api/users',
						{
							credentials: 'include',
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								org: action.payload.org,
								username: state.users.form.username,
								password: state.users.form.password,
								passwordConfirm: state.users.form.passwordConfirm,
								name: state.users.form.name,
								email: state.users.form.email,
								phone: state.users.form.phone,
								isSecretary: state.users.form.isSecretary,
								allowArchive: state.users.form.allowArchive,
								restrictedMeets: state.users.form.restrictedMeets,
								role: state.users.form.role
							})
						},
						(data) => {

							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'users.add.succeeded' }));
								dispatch(closeModal({ action: usersFormClear() }));
								dispatch(orgsLoad());
							} else if (data.status === 'error') {
								dispatch(showNotification({ severity: 'error', text: 'users.add.error' }));
							}
							else if (data.status === 'too_much_users' && data.limit) {
								dispatch(showNotification({ severity: 'error', text: 'users.add.' + data.status, textAttr: { limit: data.limit } }));
							}
							else {
								dispatch(showNotification({ severity: 'error', text: 'users.add.' + data.status, textAttr: { minUserPassLength: state.app.settings.minUserPassLength } }));


							}
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'users.add.error' }));
						}
					);
					return;
				case CUSTOM_THEME_ADD:
					const formData = new FormData();
					formData.append('prejoinBgImage', state.users.paletteSettings.prejoinBgImage.file ? state.users.paletteSettings.prejoinBgImage.file : state.users.paletteSettings.prejoinBgImage.url);
					formData.append('prejoinBgColor', state.users.paletteSettings.prejoinBgColor);
					formData.append('prejoinInfoBgColor', state.users.paletteSettings.prejoinInfoBgColor);
					formData.append('prejoinLogo', state.users.paletteSettings.prejoinLogo.file ? state.users.paletteSettings.prejoinLogo.file : state.users.paletteSettings.prejoinLogo.url);
					formData.append('prejoinInfoFontColor', state.users.paletteSettings.prejoinInfoFontColor);
					formData.append('appBgColor', state.users.paletteSettings.appBgColor);
					formData.append('appBgImage', state.users.paletteSettings.appBgImage.file ? state.users.paletteSettings.appBgImage.file : state.users.paletteSettings.appBgImage.url);
					formData.append('appBgImageAsPrejoin', state.users.paletteSettings.appBgImageAsPrejoin ? state.users.paletteSettings.appBgImageAsPrejoin : '');
					formData.append('appLogo', state.users.paletteSettings.appLogo.file ? state.users.paletteSettings.appLogo.file : state.users.paletteSettings.appLogo.url);
					appFetch(
						`${state.app.backEnd}/api/orgs/custom/${action.payload.id}`,
						{
							credentials: 'include',
							method: 'POST',
							// headers: { 'Content-Type': 'multipart/form-data' },
							body: formData
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.paletteSettings.saveSettings' }));
								dispatch(closeModal());
								dispatch(orgsLoad());
							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'users.add.error' }));
							else if (data.status === 'too_much_users' && data.limit)
								dispatch(showNotification({ severity: 'error', text: 'users.add.' + data.status, textAttr: { limit: data.limit } }));
							else
								dispatch(showNotification({ severity: 'error', text: 'users.add.' + data.status }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'users.add.error' }));
						}
					);

					return;
				case USER_DEL:
					appFetch(
						state.app.backEnd + '/api/users',
						{
							credentials: 'include',
							method: 'DELETE',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'users.del.succeeded' }));
								dispatch(closeConfirmDialog());
								dispatch(orgsLoad());
							} else
								dispatch(showNotification({ severity: 'error', text: 'users.del.error' }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'users.del.error' }));
						}
					)

					return;


				case USER_EDIT:
					appFetch(
						state.app.backEnd + '/api/users',
						{
							credentials: 'include',
							method: 'PUT',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id,
								password: state.users.form.password,
								passwordConfirm: state.users.form.passwordConfirm,
								name: state.users.form.name,
								email: state.users.form.email,
								phone: state.users.form.phone,
								isSecretary: state.users.form.isSecretary,
								active: state.users.form.active,
								allowArchive: state.users.form.allowArchive,
								restrictedMeets: state.users.form.restrictedMeets,
								role: state.users.form.role
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'users.edit.succeeded' }));
								dispatch(closeModal({ action: usersFormClear() }));
								dispatch(orgsLoad());
							} else if (data.status === 'error') {
								dispatch(showNotification({ severity: 'error', text: 'users.edit.error' }));
							}
							else
								dispatch(showNotification({ severity: 'error', text: 'users.edit.' + data.status, textAttr: { minUserPassLength: state.app.settings.minUserPassLength } }));

						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'users.edit.error' }));
						}
					);

					return;

				case PASSWORD_CHECK:
					appFetch(
						state.app.backEnd + '/api/profile',
						{
							credentials: 'include',
							method: 'PUT',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								newPassword: state.users.changePassword.newPassword,
								newPasswordConfirm: state.users.changePassword.newPasswordConfirm,
								oldPassword: state.users.changePassword.oldPassword
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.edit.succeeded' }));
								dispatch(closeModal({ action: changePasswordFormClear() }));
							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'orgs.edit.error' }));
							else {
								if (data.status === 'old_password_incorrect')
									dispatch(showNotification({ severity: 'error', text: 'profile.form.' + data.status }));
								else if (data.status === 'new_password_incorrect') {
									if (!state.users.changePassword.newPassword.match(/[а-я\s]/gi, '') && !state.users.changePassword.newPassword.length < state.app.settings.minUserPassLength) {
										dispatch(showNotification({ severity: 'error', text: 'users.add.password_incorrect', textAttr: { minUserPassLength: state.app.settings.minUserPassLength } }));
									} else {
										dispatch(showNotification({ severity: 'error', text: 'profile.form.' + data.status }));
									}
								} else if (data.status === 'new_password_not_match') {
									dispatch(showNotification({ severity: 'error', text: 'profile.form.' + data.status }));
								} else {
									dispatch(showNotification({ severity: 'error', text: 'profile.form.' + data.status }));
								}
							}
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.edit.error' }));
						}
					);

					return


			}

			next(action);

		}
	}
}
