import React from 'react';
import { copyToClipboard } from '../../../../../app/functions/copyToClipboard';
import { showNotification } from '../../../../actions';
import Action from '../../../../Action';
import { Link } from '@mui/icons-material';
import { useDispatch } from 'react-redux';


function CopyLink({ meet, meetURL }) {
    const dispatch = useDispatch();
    return (
        <Action
            title={'meetsList.copyToClipboard'}
            icon={<Link />}
            onClickHandle={
                () => {
                    copyToClipboard(`${meetURL}/${meet.room ? meet.roomId : meet._id}`)
                        .then(() => {
                            dispatch(showNotification({ severity: 'success', text: 'meetsList.linkCopied' }));
                        })
                        .catch(error => {
                            console.error(error);
                            dispatch(showNotification({ severity: 'error', text: 'meetsList.linkCopyFailed' }));
                        })
                }
            }
        >
        </Action>
    )
}

export default CopyLink;
