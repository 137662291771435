import { backdropToggled } from './actions';


export function appFetch(url, options, okCb, dispatch, failCb = () => {}, showBackdrop = true ) {
    showBackdrop && dispatch( backdropToggled( showBackdrop ) );

    fetch(url, options)
    .then(response => { return response.json() })
    .then(data => {
        if(data.isLoggedOut)
            window.location = '/';
        else
            okCb(data);
    })
    .catch(() => {
        console.log("Can't fetch " + url);
        failCb();
    })
    .finally(() => {
        showBackdrop && setTimeout(() => {
            dispatch( backdropToggled( false ) )
        }, 300);
    })
}
