import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuLicenses from "./componentsMenuLiicenses/menuLicenses";
function NoLicense() {
    const { t } = useTranslation();
    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                gap={'1.5rem'}
                sx={{ boxShadow: 1, borderRadius: '4px', maxWidth: 'md', 'margin': '0 auto', padding: 8 }}
            >
                <Box sx={{ color: '#f50057', fontSize: '1.125rem', fontWeight: 600 }}>{t('licenses.noLicense')}</Box>
                <MenuLicenses />
            </Box>
        </>
    )
}
export default NoLicense