import { Button } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

function resetButton({ t, handlerClick }) {
    return (
        <>
            <Button sx={{ ml: 'auto' }} variant="contained" onClick={
                handlerClick
            }>{t('orgs.paletteSettings.resetButton')}</Button>
        </>
    )
}
export default withTranslation()(resetButton)