import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { meetWebinarFormFieldToggled } from '../actions';


function WebinarRegistration({ t }) {
    const { _form, _webinarReg } = useSelector(state => {
        return {
            _form: state.meets.form.webinarFormFields,
            _webinarReg: state.meets.form.webinarReg
        }
    })
    const dispatch = useDispatch()
    return (
        <Box textAlign="center">
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="fio"
                                checked={_form.fio}
                                onChange={
                                    event => dispatch(meetWebinarFormFieldToggled(event.target.name))
                                }
                                disabled={true}
                            />
                        }
                        label={t('meetForm.webinar.reg.fio')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="email"
                                checked={_form.email}
                                onChange={
                                    event => dispatch(meetWebinarFormFieldToggled(event.target.name))
                                }
                                disabled={_webinarReg}
                            />
                        }
                        label={t('meetForm.webinar.reg.email')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="phone"
                                checked={_form.phone}
                                onChange={
                                    event => dispatch(meetWebinarFormFieldToggled(event.target.name))
                                }
                            />
                        }
                        label={t('meetForm.webinar.reg.phone')}
                    />
                </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="city"
                                checked={_form.city}
                                onChange={
                                    event => dispatch(meetWebinarFormFieldToggled(event.target.name))
                                }
                            />
                        }
                        label={t('meetForm.webinar.reg.city')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="address"
                                checked={_form.address}
                                onChange={
                                    event => dispatch(meetWebinarFormFieldToggled(event.target.name))
                                }
                            />
                        }
                        label={t('meetForm.webinar.reg.address')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="org"
                                checked={_form.org}
                                onChange={
                                    event => dispatch(meetWebinarFormFieldToggled(event.target.name))
                                }
                            />
                        }
                        label={t('meetForm.webinar.reg.org')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="jobTitle"
                                checked={_form.jobTitle}
                                onChange={
                                    event => dispatch(meetWebinarFormFieldToggled(event.target.name))
                                }
                            />
                        }
                        label={t('meetForm.webinar.reg.jobTitle')}
                    />
                </FormGroup>
            </FormControl>
        </Box>
    )
}



export default (withTranslation()(WebinarRegistration));
