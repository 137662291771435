import React from 'react';
import { useDispatch } from 'react-redux';
import { drawerToggled } from '../actions';

import {
  AppBar,
  Toolbar,
  IconButton
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import Header from '../../header/Header';
import AccountButton from './AccountButton';


function NavBar({ isDrawerOpen }) {
  const dispatch = useDispatch()
  return (
    <AppBar
      position="fixed"
      className={`appBar ${isDrawerOpen ? 'shift' : ''}`}
    >
      <Toolbar className="navBar">
        <IconButton
          color="inherit"
          onClick={() => dispatch(drawerToggled())}
          edge="start"
          className={`menuButton ${isDrawerOpen ? 'hide' : ''}`}
        >
          <MenuIcon />
        </IconButton>
        <Header isDrawerOpen={isDrawerOpen} />
        <AccountButton />
      </Toolbar>
    </AppBar>
  )
}

export default (NavBar);
