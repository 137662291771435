import React from 'react';
import { useSelector } from 'react-redux';
import mintLogo from './../css/images/mint_logo.svg'
import izumLogo from './../css/images/izum_logo.svg'

function Logo() {
    const { _isIzum, _locationHost, _meet } = useSelector(state => {
        return {
            _isIzum: state.app.isIzum,
            _locationHost: state.app.locationHost,
            _meet: state.meet
        }
    });

    const prejoinLogo
        = _meet.info?.custom?.prejoinLogo
            ? `https://${_locationHost}${_meet.info.custom.prejoinLogo}`
            : null;

    return (
        <img
            className="meetLogo"
            src={ prejoinLogo
                || (_isIzum
                    ? izumLogo
                    : mintLogo) } />
    )
}

export default Logo;
