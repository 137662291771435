import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, closeModalPreview } from './actions';
import { withTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ModalPreview({ t }) {
    const { _modal } = useSelector(state => {
        return {
            _modal: state.main.modalPreview
        }
    })
    const dispatch = useDispatch();

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={_modal.isOpen}
            onClose={() => { }}
            aria-labelledby="modal-dialog-title"
            aria-describedby="modal-dialog-description"

        >
            <DialogContent sx={{ p: 0 }}>
                {_modal.form}
            </DialogContent>
            <Button sx={{
                position: 'absolute',
                right: '32px',
                top: '32px'

            }}
                onClick={() => {
                    dispatch(closeModalPreview())
                }}>
                <CloseIcon
                    sx={{ fill: '#fff' }}
                    fontSize="large"
                />
            </Button>


        </Dialog>
    )
}


export default withTranslation()(ModalPreview);
