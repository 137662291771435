import {
    MEETS_FILTER_CHANGED,

    MEETS_LIST_UPDATED,

    MEETS_PAGE_CHANGED,
    MEETS_PER_PAGE_CHANGED,

    MEET_EDIT_TOGGLED,

    MEET_DATE_CHANGED,
    MEET_DURATION_HOURS_CHANGED,
    MEET_DURATION_MINUTES_CHANGED,
    MEET_PEOPLES_CHANGED,
    MEETS_FORM_FIELD_CHANGED,
    MEET_DESCRIPTION_CHANGED,
    MEET_LOBBY_TOGGLED,
    MEET_AUTO_LOBBY_TOGGLED,
    MEET_AUTO_OWNER_TOGGLED,
    MEET_START_AUDIO_MUTED_TOGGLED,
    MEET_START_VIDEO_MUTED_TOGGLED,
    MEET_PASS_ENABLED_TOGGLED,
    MEET_PASS_CHANGED,
    MEET_PERIODIC_TOGGLED,
    MEET_DAYS_OF_WEEK_CHANGED,
    MEET_WEBINAR_REG_TOGGLED,
    MEET_WEBINAR_FORM_FIELD_TOGGLED,
    MEET_GET_MODERATOR_LINK,
    MEET_AUTO_RECORDING,
    MEETS_VISIBLE_RECORDS,

} from './actionTypes';

import { formFields } from './form/formFields';


const meetsState = {
    filter: 'today', // today, tomorrow, week, all, last_today, last_yesterday, last_week, last_all, rooms, current
    filterCategory: 'future', // future, past
    list: [],
    current: [],
    currentConferences: [],
    page: 0,
    meetsPerPage: 10,
    moderatorLink: null,
    meetsVisibleRecords: false,//Pagination view: number of records/total number of records
    editId: null,
    form: formFields
}


export function meetsReducer(state = meetsState, action) {
    switch (action.type) {
        case MEETS_FILTER_CHANGED:
            return {
                ...state,
                filter: action.payload.filter,
                filterCategory: action.payload.filterCategory,
                list: action.payload.list,
                page: action.payload.page
            }

        case MEETS_LIST_UPDATED:
            return {
                ...state,
                list: action.payload.meets,
                current: action.payload.currentMeets,
                currentConferences: action.payload.currentConferences,
                page: action.payload.page
            }

        case MEETS_PAGE_CHANGED:
            return { ...state, page: action.payload.page }

        case MEETS_PER_PAGE_CHANGED:
            return { ...state, page: action.payload.page, meetsPerPage: action.payload.meetsPerPage }

        case MEET_EDIT_TOGGLED:
            return { ...state, editId: action.payload.editId, form: action.payload.form }

        case MEET_DATE_CHANGED:
            return { ...state, form: { ...state.form, date: action.payload.date } }

        case MEET_DURATION_HOURS_CHANGED:
            return {
                ...state,
                form: {
                    ...state.form,
                    durationHours: action.payload.durationHours,
                    durationMinutes: action.payload.durationMinutes
                }
            }

        case MEET_DURATION_MINUTES_CHANGED:
            return { ...state, form: { ...state.form, durationMinutes: action.payload.durationMinutes } }

        case MEET_PEOPLES_CHANGED:
            return { ...state, form: { ...state.form, peoples: action.payload.peoples } }

        case MEETS_FORM_FIELD_CHANGED:
            return { ...state, form: { ...state.form, [action.payload.field]: action.payload.value } }

        case MEET_DESCRIPTION_CHANGED:
            return { ...state, form: { ...state.form, description: action.payload.description } }

        case MEET_LOBBY_TOGGLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    lobby: action.payload.lobby,
                    autoLobby: action.payload.autoLobby,
                    passEnabled: action.payload.passEnabled,
                    pass: action.payload.pass
                }
            }

        case MEET_AUTO_LOBBY_TOGGLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    autoLobby: action.payload.autoLobby,
                    lobby: action.payload.lobby,
                    passEnabled: action.payload.passEnabled,
                    pass: action.payload.pass
                }
            }

        case MEET_AUTO_OWNER_TOGGLED:
            return { ...state, form: { ...state.form, autoOwner: action.payload.autoOwner } }

        case MEET_START_AUDIO_MUTED_TOGGLED:
            return { ...state, form: { ...state.form, startAudioMuted: action.payload.startAudioMuted } }
        case MEET_AUTO_RECORDING:
            return { ...state, form: { ...state.form, autoRecording: action.payload.autoRecording } }
        case MEET_START_VIDEO_MUTED_TOGGLED:
            return { ...state, form: { ...state.form, startVideoMuted: action.payload.startVideoMuted } }
        case MEETS_VISIBLE_RECORDS:
            return {
                ...state, meetsVisibleRecords: action.payload.meetsVisibleRecords
            }

        case MEET_PASS_ENABLED_TOGGLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    passEnabled: action.payload.passEnabled,
                    pass: action.payload.pass,
                    autoLobby: action.payload.autoLobby,
                    lobby: action.payload.lobby
                }
            }

        case MEET_PASS_CHANGED:
            return { ...state, form: { ...state.form, pass: action.payload.pass } }

        case MEET_PERIODIC_TOGGLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    periodic: action.payload.periodic,
                    daysOfWeek: { ...meetsState.form.daysOfWeek }
                }
            }

        case MEET_DAYS_OF_WEEK_CHANGED:
            return { ...state, form: { ...state.form, daysOfWeek: action.payload.daysOfWeek } }

        case MEET_WEBINAR_REG_TOGGLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    webinarReg: action.webinarReg,
                    webinarFormFields:
                        !action.webinarReg ?
                            formFields.webinarFormFields
                            :
                            { ...state.form.webinarFormFields, email: true }
                }
            }

        case MEET_WEBINAR_FORM_FIELD_TOGGLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    webinarFormFields: {
                        ...state.form.webinarFormFields,
                        [action.fieldName]: !state.form.webinarFormFields[action.fieldName]
                    }
                }
            }

        case MEET_GET_MODERATOR_LINK:
            return { ...state, moderatorLink: action.payload.moderatorLink }

        default:
            return state
    }
}
