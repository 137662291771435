import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { closeConfirmDialog } from './actions';

import { withTranslation } from 'react-i18next';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';


function ConfirmDialog({ t }) {
    const { _confirmDialog } = useSelector(state => {
        return {
            _confirmDialog: state.main.confirmDialog
        }
    });
    const dispatch = useDispatch();
    return (
        <Dialog
            open={_confirmDialog.isOpen}
            onClose={() => dispatch(closeConfirmDialog())}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t(_confirmDialog.title)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t(_confirmDialog.text.key, _confirmDialog.text.vars)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(_confirmDialog.action)} color="primary">
                    {t(_confirmDialog.actionButton)}
                </Button>
                <Button onClick={() => dispatch(closeConfirmDialog())} color="primary" autoFocus>
                    {t('confirmDialog.closeButton')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default (withTranslation()(ConfirmDialog));
