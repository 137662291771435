import React from 'react';
import { Typography } from '@mui/material';

function RecomendedBrowsers() {
    return (
        <>
            <Typography>
                <a href="https://www.google.com/intl/ru_ru/chrome/">Google Chrome</a>
                <br />
                <a href="https://browser.yandex.ru/">Яндекс Браузер</a>
                <br />
                <a href="https://www.opera.com/ru">Opera</a>
            </Typography>
        </>
    )
}

export default RecomendedBrowsers;
