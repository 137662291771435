import { TEST_STATUSES } from "../const";
import { CONNECTORS_LOADED, EDIT_CONNECTOR_STATE, SELECT_LDAP, SETTINGS_FORM_CLEAR, USERS_FORM_FIELD_CHANGED_LDAP, TEST_CONNECTION } from "./actionTypes";
const settingsState = {
    connectors: [],
    form: {
        name: '',
        selectLdap: 'LDAP',
        URLs: [],
        userPrincipalName: "",
        password: "",
        displayName: "displayName",
        mail: "mail",
        mobile: "mobile",
        baseDN: '',
        domain: ''
    },
    testStatus: TEST_STATUSES.UNKNOWN
};

export function settingsReducer(state = settingsState, action) {
    switch (action.type) {
        case TEST_CONNECTION:
            return {
                ...state,
                testStatus: action.payload
            }
        case SELECT_LDAP:
            return {
                ...state,
                form: {
                    selectLdap: action.payload
                }
            }
        case USERS_FORM_FIELD_CHANGED_LDAP:
            return {
                ...state, form: {
                    ...state.form,
                    [action.payload.field]: action.payload.value
                }
            }
        case SETTINGS_FORM_CLEAR:
            return { ...state, form: settingsState.form }
        case CONNECTORS_LOADED:
            return {
                ...state,
                connectors: action.payload.connectors,
            }
        case EDIT_CONNECTOR_STATE:
            return {
                ...state,
                form: {
                    name: action.payload.name,
                    selectLdap: 'LDAP',
                    URLs: action.payload.URLs,
                    userPrincipalName: action.payload.userPrincipalName,
                    password: action.payload.password,
                    displayName: action.payload.mapping.displayName,
                    mail: action.payload.mapping.mail,
                    mobile: action.payload.mapping.mobile,
                    baseDN: action.payload.baseDN,
                    domain: action.payload.domain,
                },
            }
        default:
            return state
    }
}
