import React from "react";
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { useDispatch } from "react-redux";
import Connectors from "./Connectors";
import Form from "./Form";
import { connectorAdd, settingsFormClear } from "./actions";
import Action from "../../../Action";

import { Box } from "@mui/material";
import { showModal } from "../../../actions";

function LDAP() {
    const dispatch = useDispatch();
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Action
                    title={'settings.ldap.addConnector'}
                    icon={<AddCircleTwoToneIcon fontSize="large" />}
                    onClickHandle={
                        () => {
                            dispatch(
                                showModal({
                                    title: 'settings.ldap.settings',
                                    form: <Form addNewConnector={true} />,
                                    actionButton: 'settings.ldap.form.addBtn',
                                    action: connectorAdd(),
                                    closeAction: { action: settingsFormClear() },
                                    sizeDialog: 'sm',
                                    disabledBtn: true
                                })
                            )
                        }
                    }
                />

            </Box>
            <Connectors />

        </>

    )
}
export default LDAP