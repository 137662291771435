import React from "react";
import { useSelector } from "react-redux";
import { uploadLicenseFile } from "../actions";
import Dropzone from "../../Dropzone";
function Form() {
    const { licenseFile } = useSelector(state => {
        return {
            licenseFile: state.licenses.licenseFile
        }
    })

    return (
        <><Dropzone stateFile={licenseFile} fileHandle={uploadLicenseFile} /></>
    );
}
export default Form