import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { meetsFilterChanged } from '../content/meets/actions';
import {
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';

function MeetsFilterItem({ filterName, filterIcon, filterText }) {
    const dispatch = useDispatch();
    const { _selectedMenu, _filter } = useSelector(state => {
        return {
            _selectedMenu: state.main.selectedMenu,
            _filter: state.meets.filter
        }
    })
    function onItemClick(filter) {
        dispatch(meetsFilterChanged({ filter }));
    }
    return (
        <ListItem
            button
            selected={filterName === _filter && _selectedMenu === 'MeetsFilter'}
            onClick={() => onItemClick(filterName)}
        >
            <ListItemIcon>{filterIcon}</ListItemIcon>
            <ListItemText primary={filterText} className={filterName} />
        </ListItem>
    )
}

export default (MeetsFilterItem);
