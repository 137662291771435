export const LICENSES_LOAD = 'LICENSES_LOAD';
export const LICENSES_LOADED = 'LICENSES_LOADED';
export const LICENSES_LOAD_BY_PROFILE = 'LICENSES_LOAD_BY_PROFILE';
export const LICENSES_AVAILABLE_LOAD = 'LICENSES_AVAILABLE_LOAD';
export const LICENSES_AVAILABLE_LOADED = 'LICENSES_AVAILABLE_LOADED';
export const FILTER_LICENSES = 'FILTER_LICENSES'
export const NEW_FILTER_LICENSES = 'NEW_FILTER_LICENSES';
export const UPLOAD_LICENSE_FILE = 'UPLOAD_LICENSE_FILE';
export const SEND_UPLOAD_FILE = 'SEND_UPLOAD_FILE';
export const GENERATE_FILE = "GENERATE_FILE";
export const SEND_UPLOAD_FILE_CLEAR = 'SEND_UPLOAD_FILE_CLEAR';