import React from 'react';
import {
    isBrowserOk
} from './functions';
import UnsupportedBrowsers from './UnsupportedBrowsers';
import WarningBrowsers from './WarningBrowsers';



function Message(props) {
    if (!isBrowserOk())
        return <UnsupportedBrowsers />

    return (
        <>
            {props.children}
            <WarningBrowsers />
        </>
    )

}

export default Message;
