import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    FormControlLabel,
    Switch,
    Tooltip,
    TextField
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';


function Option({ name,
    showable = true,
    isSwitch = true,
    disabled = false,
    checked,
    onChangeHandler,
    t }) {

    if (!showable)
        return null;
    return (
        <div className="option">
            {isSwitch ?
                <>
                    <FormControlLabel
                        label={t(`meetForm.${name}`)}
                        labelPlacement="end"
                        control={
                            <Switch
                                disabled={disabled}
                                checked={checked}
                                onChange={onChangeHandler}
                                name={name}
                                color="primary"
                            />
                        }
                    />
                    <Tooltip title={t(`meetForm.${name}Tooltip`)} arrow>
                        <HelpOutline color="primary" fontSize="small" />
                    </Tooltip>
                </>
                :
                <TextField
                    disabled={disabled}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    label={t(`meetsList.${name}`)}
                    value={checked}
                    onChange={onChangeHandler}
                />
            }
        </div>
    )

}


export default withTranslation()(Option);
