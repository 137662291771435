import React from 'react';
import { withTranslation } from 'react-i18next';
import Action from '../../../../Action';
import {
    FactCheckTwoTone,
    CheckTwoTone
} from '@mui/icons-material';
import { showModal } from '../../../../actions';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import CopyWebinarLink from './CopyWebinarLink';
import { useDispatch } from 'react-redux';


function ShowRegs({ t, meet, urlBase }) {
    const dispatch = useDispatch();

    function showRegs() {
        const { webinarFormFields, meetRegs } = meet;
        const fields = Object.keys(webinarFormFields).filter(field => webinarFormFields[field]);
        const tableFields = fields.map(field => {
            return <TableCell align="center" key={field}>{t(`meetForm.webinar.reg.${field}`)}</TableCell>
        });

        if (meet.webinarReg) {
            tableFields.push(<TableCell align="center" key="inviteSended">{t('meetForm.webinar.reg.inviteSended')}</TableCell>);
            tableFields.push(<TableCell align="center" key="personalLink">{t('meetForm.webinar.reg.personalLink')}</TableCell>);
        }

        const tableRows = meetRegs.map(reg => {
            const tableCells = [];
            fields.map(field => {
                tableCells.push(
                    <TableCell align="center">{reg[field]}</TableCell>
                );
            });
            if (meet.webinarReg) {
                tableCells.push(<TableCell align="center">{reg['inviteSended'] ? <CheckTwoTone /> : null}</TableCell>);
                const webinarURL = `${urlBase}/webinar/p${reg._id}`;
                tableCells.push(
                    <TableCell align="center">
                        <CopyWebinarLink meet={meet} webinarURL={webinarURL} dispatch={dispatch} />
                    </TableCell>
                );
            }
            return <TableRow>{tableCells}</TableRow>
        });

        return (
            <TableContainer component={Paper}>
                <Table aria-label="regsTable">
                    <TableHead>
                        <TableRow>
                            {tableFields}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    if (!meet.webinar) {
        return null;
    }

    return (
        <Action
            title={'meetsList.showRegs'}
            icon={<FactCheckTwoTone />}
            onClickHandle={
                () => {
                    dispatch(
                        showModal({
                            title: 'meetsList.showRegs',
                            form: showRegs(),
                            closeButton: 'meetForm.webinar.reg.close'
                        })
                    );
                }
            }
        >
        </Action>
    )

}

export default withTranslation()(ShowRegs);
