import React from "react";
import Action from "../../../../Action";
import { useDispatch } from "react-redux";

import PaletteIcon from '@mui/icons-material/Palette';

import FormPalette from "../paletteSettings/form/FormPalette";
import { showModal } from "../../../../actions";
import { clearCustomThemeAll, customThemeAdd } from "../../actions";


function PaletteSettings({ org }) {
    const dispatch = useDispatch();
    return (
        <Action
            title={'orgs.paletteSettings.title'}
            icon={<PaletteIcon />}
            onClickHandle={
                () => {
                    dispatch(
                        showModal({
                            title: 'orgs.paletteSettings.title',
                            form: <FormPalette org={org} />,
                            actionButton: 'orgs.paletteSettings.button',
                            action: customThemeAdd({ id: org._id }),
                            closeAction: { action: clearCustomThemeAll() },
                        })
                    )
                }
            }
        />
    )
}
export default PaletteSettings