const SELECT_LDAP = 'SELECT_LDAP';
const USERS_FORM_FIELD_CHANGED_LDAP = "USERS_FORM_FIELD_CHANGED_LDAP";
const SETTINGS_FORM_CLEAR = "SETTINGS_FORM_CLEAR";
const CONNECTOR_ADD = "CONNECTOR_ADD";
const CONNECTORS_LOADED = "CONNECTORS_LOADED";
const CONNECTORS_LOAD = "CONNECTORS_LOAD";
const CONNECTOR_DELETE = "CONNECTOR_DELETE"
const CONNECTOR_EDIT = "CONNECTOR_EDIT";
const EDIT_CONNECTOR_STATE = "EDIT_CONNECTOR_STATE";
const CONNECTOR_TEST = "CONNECTOR_TEST";
const TEST_CONNECTION = 'TEST_CONNECTION';
const CONNECTOR_SYNC = "CONNECTOR_SYNC";
const RELOAD_SYNC_CONNECTORS = "RELOAD_SYNC_CONNECTORS";
export {
    SELECT_LDAP,
    USERS_FORM_FIELD_CHANGED_LDAP,
    SETTINGS_FORM_CLEAR,
    CONNECTOR_ADD,
    CONNECTORS_LOADED,
    CONNECTORS_LOAD,
    CONNECTOR_DELETE,
    CONNECTOR_EDIT,
    EDIT_CONNECTOR_STATE,
    CONNECTOR_TEST,
    TEST_CONNECTION,
    CONNECTOR_SYNC,
    RELOAD_SYNC_CONNECTORS
}