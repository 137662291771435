import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { meetsListUpdate, meetEditToggled } from './actions';
import Add from './Add';
import List from './list/List';
import Edit from './Edit';
import useInterval from '../../../hooks/hooks';


function Meets() {
    const dispatch = useDispatch();
    const { _editId, _filter, _isRooms, _isWebinars, _isCurrents, _updateMeetsListInterval } = useSelector(state => {
        const { filter } = state.meets;
        return {
            _editId: state.meets.editId,
            _filter: filter,
            _isRooms: filter === 'rooms',
            _isWebinars: filter === 'webinars',
            _isCurrents: filter === 'current',
            _updateMeetsListInterval: state.app.settings.updateMeetsListInterval
        }
    })

    function updateList(background = false) {
        dispatch(meetsListUpdate({ filter: _filter, background }));
    }

    function initMeetFrom() {
        dispatch(meetEditToggled({ editId: null }));
    }

    useEffect(() => {
        initMeetFrom();
        updateList();
    }, [])

    useInterval(() => {
        updateList(true)
    }, _updateMeetsListInterval);


    if (_editId)
        return <Edit isRooms={_isRooms} isWebinars={_isWebinars} updateList={updateList} />

    return (
        <>
            <Add isRooms={_isRooms} isWebinars={_isWebinars} isCurrents={_isCurrents} />
            <List isRooms={_isRooms} />
        </>
    )
}


export default Meets;
