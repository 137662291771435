import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { isPastMeets } from '../functions';


function Caption({ meets, isRooms, t }) {
    return (
        <Typography className="caption">
            {
                isRooms ?
                    t('sideBar.meetsFilter.' + meets.filter)
                    :
                    isPastMeets(meets.filterCategory) ?
                        t('sideBar.meetsFilter.' + meets.filter.split('_')[1])
                        + ' — ' +
                        t('sideBar.meetsFilter.past')
                        :
                        t('sideBar.meetsFilter.' + meets.filter)
                        + ' — ' +
                        t('sideBar.meetsFilter.future')
            }
        </Typography>
    )
}

export default withTranslation()(Caption);
