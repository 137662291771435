import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import DropzoneFile from "./dropzoneFiles/DropzoneFile";
import { Box, Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { showModalPreview } from "../../../../actions";
import PreviewImage from "./previews/previewImage";
import DropzoneFileLogo from "./dropzoneFiles/DropzoneFileLogo";
import { clearCustomTheme, dashboardColorPickerBg, dashboardImage, opacityBg, selectColor } from "../../actions";
import { Colorize } from "@mui/icons-material";
import InfoColorPicker from "./colorPickers/InfoColorPicker";
import TextColorPicker from "./colorPickers/TextColorPicker";
import BgColorPicker from "./colorPickers/BgColorPicker";
import { styled } from '@mui/material/styles';
import ResetButton from "./settingsComponents/resetButton";

function SettiingsLoginPage({ t, org }) {
    const dispatch = useDispatch();
    const { _opacityBg, _selectColor, _prejoinBgImage, _prejoinBgColor } = useSelector(state => {
        return {
            _opacityBg: state.users.paletteSettings.opacityBg,
            _selectColor: state.users.paletteSettings.selectColor,
            _prejoinBgImage: state.users.paletteSettings.prejoinBgImage,
            _prejoinBgColor: state.users.paletteSettings.prejoinBgColor,
        }
    });
    const handleChange = () => {
        dispatch(opacityBg(!_opacityBg));
    }

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 58,
        height: 38,
        margin: 0,
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2',
        },
        '& .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2',

        },
    }));

    const [selectTypeBg, setSelectTypeBg] = useState(_selectColor);
    const handleChangeSelect = (event) => {
        dispatch(selectColor(!_selectColor));
        setSelectTypeBg(_selectColor);
        if (_selectColor) {
            dispatch(dashboardImage({ image: null }));
        } else {
            dispatch(dashboardColorPickerBg('rgba(54, 58, 72, 1)'));
        }

    };

    return (
        <>
            <Grid container columns={16} rowGap={2} >
                <Grid item xs={8}>
                    <Typography variant="h6"> {t('orgs.paletteSettings.titleBg')}</Typography>

                    <Grid sx={{ display: "flex", alignItems: 'center' }} item>
                        <Grid item>{t('orgs.paletteSettings.selectColor')}</Grid>
                        <FormControlLabel sx={{ m: 0 }}
                            control={<MaterialUISwitch sx={{ m: 1 }} />}
                            checked={_selectColor}
                            onChange={handleChangeSelect}
                        />
                        <Grid item>{t('orgs.paletteSettings.selectimage')}</Grid>
                    </Grid>
                </Grid>

                <Grid item xs={8} >
                    {_selectColor ? <DropzoneFile /> : <BgColorPicker label={t('orgs.paletteSettings.bgColor')} icon={<Colorize fontSize='large' sx={{ 'fill': '#1565c0' }} />} />
                    }

                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6"> {t('orgs.paletteSettings.titleBgColor')}</Typography>
                </Grid>

                <Grid item xs={8} gap={1} sx={{ 'position': 'relative', display: 'grid', gridTemplateColumns: 'repeat(2,1fr)' }}  >
                    <Grid item  >
                        <InfoColorPicker label={t('orgs.paletteSettings.bgColor')} icon={<Colorize fontSize='large' sx={{ 'fill': '#1565c0' }} />} org={org} />
                    </Grid>
                    <Grid item >
                        <TextColorPicker label={t('orgs.paletteSettings.textСolor')} icon={<Colorize fontSize='large' sx={{ 'fill': '#1565c0' }} />} org={org} />
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6"> {t('orgs.paletteSettings.titleLogo')}</Typography>
                    <Typography sx={{ color: "#9e9e9e", fontSize: '0.87rem', width: '80%', mt: 1 }}> {t('orgs.paletteSettings.logoSizePrejoin')}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <DropzoneFileLogo />
                </Grid>
                <Button variant="contained" sx={{ ml: 'auto' }} onClick={() => {
                    dispatch(
                        showModalPreview({
                            form: <PreviewImage org={org} />,
                        })

                    )
                }}>{t('orgs.paletteSettings.preview')}</Button>
            </Grid>
            <Box sx={{ display: "flex", mt: 2 }}>
                <ResetButton handlerClick={() => {
                    dispatch(clearCustomTheme())
                }} />
            </Box>

        </>
    )
}
export default withTranslation()(SettiingsLoginPage)