import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import GetRecs from './GetRecs';
import ShowRegs from './ShowRegs';
import SendToEmail from './SendToEmail';
import CopyLink from './CopyLink';
import CopyWebinarLink from './CopyWebinarLink';
import CopyModeratorLink from './CopyModeratorLink';
import Play from './Play';
import Edit from './Edit';
import Delete from './Delete';
import { isPastMeets } from '../../functions';

function Actions({ meet, date, isCurrent }) {
    const { _meets, _urlBase } = useSelector(state => {
        return {
            _meets: state.meets,
            _urlBase: state.app.urlBase
        }
    });
    const dispatch = useDispatch();
    const { filterCategory } = _meets;

    if (isPastMeets(filterCategory))
        return <GetRecs meet={meet} />;

    const meetURL = `${_urlBase}/${meet.room ? 'room' : 'meet'}`;
    const webinarURL = `${_urlBase}/webinar/${meet._id}`;

    return (
        <>
            <ShowRegs meet={meet} urlBase={_urlBase} dispatch={dispatch} />
            <SendToEmail meet={meet} date={date} meetURL={meetURL} urlBase={_urlBase} />
            <CopyLink meet={meet} meetURL={meetURL} dispatch={dispatch} />
            <CopyWebinarLink meet={meet} webinarURL={webinarURL} dispatch={dispatch} />
            <CopyModeratorLink meet={meet} meetURL={meetURL} dispatch={dispatch} />
            <Play meet={meet} meetURL={meetURL} dispatch={dispatch} />
            <Edit meet={meet} dispatch={dispatch} />
            <Delete meet={meet} isCurrent={isCurrent} dispatch={dispatch} />
        </>
    )

}



export default Actions;
