
import React from "react";
import SettingsTabs from "./SettingsTabs";
import { Paper } from "@mui/material";
function Settings() {
    return (
        <>
            <Paper>
                <SettingsTabs />
            </Paper>

        </>
    );
}

export default Settings