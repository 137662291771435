import React from 'react';
import { useEffect } from 'react';

function Iframe({ info, webinar, displayName, locationHost }) {
    useEffect(() => {
        document.getElementById('root').style.overflow = 'hidden';
        const options = {
            roomName: info._id,
            configOverwrite: {
                deploymentInfo: {
                    region: info._id,
                    userRegion: info._id
                }
            },
            parentNode: document.querySelector('#iframe')
        };

        if (webinar) {
            options.userInfo = { displayName: info.webinarReg ? info.displayName : displayName };
            options.configOverwrite.webinarMode = true;
        } else {
            options.configOverwrite.startWithAudioMuted = info.startAudioMuted;
            options.configOverwrite.startWithVideoMuted = info.startVideoMuted;
        }

        if (info.webinarFormFields) {
            options.configOverwrite.disablePolls = true;
            options.configOverwrite.quickHideMode = true;
        }

        if (info.jwt) {
            options.jwt = info.jwt;
        }

        const api = new window.JitsiMeetExternalAPI(locationHost, options);
        api.addEventListener('participantRoleChanged', event => {
            if (event.role === 'moderator') {
                if (info.lobby) {
                    api.executeCommand('toggleLobby', true);
                }
                if (info.autoLobby && info.peoples) {
                    api.executeCommand('toggleLobby', true, info.peoples);
                }
                if (Boolean(info.pass)) {
                    api.executeCommand('password', info.pass);
                }
            }
        })
    }, [])

    return (
        <div id="iframe"></div>
    )

}

export default Iframe;
