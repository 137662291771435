import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    meetsFilterOptionToggled,
    meetsFilterClear
} from './actions';
import {
    FormControl,
    Stack
} from '@mui/material';
import {
    OndemandVideo,
    Clear
} from '@mui/icons-material';
import Option from './Option';
import { isPastMeets } from '../functions';
import { meetsVisibleRecords } from '../actions';

function Options({ showClearButton, t }) {
    const { _isPastMeets, _meetsFilter } = useSelector(state => {
        return {
            _isPastMeets: isPastMeets(state.meets.filterCategory),
            _meetsFilter: state.meetsFilter
        }
    })
    const { recordings } = _meetsFilter;
    const dispatch = useDispatch();
    return (
        <FormControl size="small" margin="dense">
            <Stack direction="row" spacing="1">
                <Option
                    showable={_isPastMeets}
                    title={t('meetsFilter.recordings')}
                    enabled={recordings}
                    onClickHandle={() => {
                        dispatch(meetsFilterOptionToggled('recordings'));
                        dispatch(meetsVisibleRecords());
                    }
                    }

                    icon={<OndemandVideo />}
                />
                <Option
                    showable={showClearButton}
                    title={t('meetsFilter.clear')}
                    enabled={false}
                    onClickHandle={() => dispatch(meetsFilterClear())}
                    icon={<Clear />}
                />
            </Stack>
        </FormControl>
    )

}
export default (withTranslation()(Options));
