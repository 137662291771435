import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { licensesLoadByProfile } from '../licenses/actions';
import { prettyDateTime } from '../../../PrettyDateTime';
import {
    Box,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button
} from '@mui/material';
import {
    CheckTwoTone,
    ClearTwoTone
} from '@mui/icons-material'
import { showModal } from '../../actions';
import FormPassword from './form/FormPassword';
import { changePasswordFormClear, passwordCheck } from '../users/actions';
import { USER_DEFAULT_ADMIN_NAME } from '../../../const';



function Profile() {
    const { t } = useTranslation();
    function isFeatureAllowed(feature) {
        if (Boolean(feature))
            return <CheckTwoTone />;
        return <ClearTwoTone />;
    }
    const dispatch = useDispatch();
    const { _user, _ts, _restrictedArchive, _restrictedMeets, _version, _isLicenseLoaded, _LDAPEnabled, _multiDomain } = useSelector(state => {
        return {
            _user: state.app.user,
            _ts: state.app.settings.ts,
            _restrictedArchive: state.app.settings.restrictedArchive,
            _restrictedMeets: state.app.settings.restrictedMeets,
            _version: state.app.settings.version,
            _isLicenseLoaded: state.app.settings.isLicenseLoaded,
            _LDAPEnabled: state.app.settings.LDAPEnabled,
            _multiDomain: state.app.settings.multiDomain,
        }
    })

    const isFormHiddenOrgDisabled = _LDAPEnabled && _user.username !== USER_DEFAULT_ADMIN_NAME;
    useEffect(() => {
        if (_isLicenseLoaded) {
            dispatch(licensesLoadByProfile());
        }
    }, [_isLicenseLoaded])


    return (
        <Box className="profile">
            <TableContainer component={Paper}>
                <Table aria-label="ProfileTable">
                    <TableBody>
                        <TableRow>
                            <TableCell>{t('orgs.name')}</TableCell>
                            <TableCell>{_user.org.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('orgs.exp')}</TableCell>
                            <TableCell>{prettyDateTime(_user.org.exp).split(' ')[0]}</TableCell>
                        </TableRow>
                        {_isLicenseLoaded && _user.org.participants !== undefined ?
                            <>
                                <TableRow>
                                    <TableCell>{t('orgs.participants')}</TableCell>
                                    <TableCell>{_user.org.participants}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t('orgs.meetsPerTime')}</TableCell>
                                    <TableCell>{_user.org.meetsPerTime}</TableCell>
                                </TableRow>
                            </>
                            :
                            null
                        }
                        {_isLicenseLoaded && Array.isArray(_user.org.licenses) ?
                            <TableRow>
                                <TableCell>{t('orgs.licenses')}</TableCell>
                                <TableCell>
                                    {_user.org.licenses.length > 0 ?
                                        _user.org.licenses.map(license => {
                                            if (license.endTime * 1000 > _ts)
                                                return (
                                                    <div key={license.number} className="license">
                                                        {`ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`}
                                                        <p className="time">
                                                            {`${t('licenses.startTime')}: ${prettyDateTime(license.startTime).split(' ')[0]}`}
                                                            <br />
                                                            {`${t('licenses.endTime')}: ${prettyDateTime(license.endTime).split(' ')[0]}`}
                                                        </p>
                                                    </div>
                                                )
                                        })
                                        :
                                        t('orgs.empty')
                                    }
                                </TableCell>
                            </TableRow>
                            :
                            null
                        }
                        <TableRow>
                            <TableCell>{t('users.form.username')}</TableCell>
                            <TableCell>{(_user.username.indexOf('@') !== -1 && _multiDomain) ? _user.username.split('@')[0] : _user.username}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('users.form.name')}</TableCell>
                            <TableCell>{_user.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('users.form.email')}</TableCell>
                            <TableCell>{_user.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('users.form.phone')}</TableCell>
                            <TableCell>{_user.phone}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('users.form.isSecretary')}</TableCell>
                            <TableCell>
                                {isFeatureAllowed(_user.isSecretary)}
                            </TableCell>
                        </TableRow>
                        {_restrictedArchive ?
                            <TableRow>
                                <TableCell>{t('users.form.allowArchive')}</TableCell>
                                <TableCell>
                                    {isFeatureAllowed(_user.allowArchive || _user.isAdmin)}
                                </TableCell>
                            </TableRow>
                            :
                            null
                        }
                        {_restrictedMeets ?
                            <TableRow>
                                <TableCell>{t('users.form.restrictedMeets')}</TableCell>
                                <TableCell>
                                    {isFeatureAllowed(_user.restrictedMeets)}
                                </TableCell>
                            </TableRow>
                            :
                            null
                        }
                        <TableRow>
                            <TableCell>{t('app.version')}</TableCell>
                            <TableCell>{_version}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {isFormHiddenOrgDisabled ? null
                : <Button variant="contained" sx={{ mt: 4 }} onClick={() => {
                    dispatch(
                        showModal({
                            title: 'profile.form.title',
                            form: <FormPassword />,
                            actionButton: 'profile.form.save',
                            action: passwordCheck({}),
                            closeAction: { action: changePasswordFormClear() }

                        })
                    )
                }}>{t('profile.form.button')}</Button>}
        </Box>
    )

}

export default Profile;
