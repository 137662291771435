import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { prettyDateTime } from '../PrettyDateTime';
import { prettyDuration } from '../PrettyDuration';
import { Typography } from '@mui/material';
import meetStatuses from './meetStatuses';
import Message from './Message';
import Links from './Links';
import Limits from './Limits';
import WebinarRegForm from './WebinarRegForm';

function Info({ meetId, webinar, t }) {
    const _meet  = useSelector(state => state.meet);

    function renderMessage() {
        const { status } = _meet;
        const { webinarReg } = _meet.info;
        const isPersonalWebinarLink = webinar && meetId[0] === 'p';

        if (status === meetStatuses.FINISHED) {
            return <Typography>{t(`meet.${status}`)}</Typography>
        }

        if (webinar && (webinarReg || status === meetStatuses.WEBINAR_REG) && !isPersonalWebinarLink) {
            return <WebinarRegForm />
        }

        return (
            <Message>
                <Links meetId={meetId} webinar={webinar} isPersonalWebinarLink={isPersonalWebinarLink} />
                <Limits />
            </Message>
        )
    }

    const { showInfo, status } = _meet;
    const { duration, description, date } = _meet.info;

    if (showInfo) {
        const { hours, minutes } = prettyDuration(duration);

        return (
            <>
                <Typography>
                    {` ${t('meet.description')}: `}
                    <i>{description}</i>
                </Typography>
                <br />
                <Typography>
                    {` ${t('meet.datetime')}: `}
                    <b>{prettyDateTime(date)}</b>
                </Typography>
                <Typography>
                    {` ${t('meet.duration')}: `}
                    <b>
                        {` ${hours} ${t('meetForm.duration.hours')} `}
                        {` ${minutes} ${t('meetForm.duration.minutes')} `}
                    </b>
                </Typography>
                <br />

                {renderMessage()}
            </>
        )
    }

    return <Typography>{t(`meet.${status}`)}</Typography>
}

export default withTranslation()(Info);
