import React, { forwardRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { meetsFilterDateRangeChanged } from './actions';
import { TextField } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { isPastMeets, isAllMeets } from '../functions';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';


registerLocale('ru', ru);


function DateRange({ t }) {
    const { _dateRange, _isPastMeets, _isAllMeets, _settings } = useSelector(state => {
        return {
            _dateRange: state.meetsFilter.dateRange,
            _isPastMeets: isPastMeets(state.meets.filterCategory),
            _isAllMeets: isAllMeets(state.meets.filter),
            _settings: state.app.settings
        }
    });
    const dispatch = useDispatch();

    const [startDate, endDate] = _dateRange;

    if (!_isAllMeets)
        return null;

    let dateRange = startDate ? `${moment(startDate).format('DD.MM.YYYY')} - ` : ``;
    if (endDate)
        dateRange += `${moment(endDate).format('DD.MM.YYYY')}`;

    const DatePickerInput = forwardRef(
        ({ value, onClick }, ref) => (
            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label={t('meetsFilter.dateRange')}
                onClick={onClick}
                value={dateRange}
                className="dateRange"
            />
        )
    );

    return (
        <DatePicker
            minDate={_isPastMeets ? false : new Date(_settings.ts)}
            selectsRange={true}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={dates => dispatch(meetsFilterDateRangeChanged(dates || [null, null]))}
            isClearable={true}
            shouldCloseOnSelect={false}
            locale='ru'
            customInput={<DatePickerInput ref={React.createRef()} />}
        />
    )
}



export default withTranslation()(DateRange);
