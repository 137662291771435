import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    TableRow,
    TableCell
} from '@mui/material';
import { prettyDateTime } from '../../../../PrettyDateTime';
import Users from '../Users';
import Actions from './actions/Actions';
import { useSelector } from 'react-redux';
import { USER_PERMS } from '../../../../const';

function Items({ licenses, ts, orgs, page, orgsPerPage, isPoolLicense, t }) {

    const { _user, _multiDomain, _defaultDomain, _LDAPEnabled } = useSelector((state => {
        return {
            _user: state.app.user,
            _multiDomain: state.app.settings.multiDomain,
            _defaultDomain: state.app.settings.domain,
            _LDAPEnabled: state.app.settings.LDAPEnabled,
        }
    }));

    const userOrg = _user?.org;
    const renderLicenses = (org) => {
        if (org.licenses.length === 0 || licenses.length === 0)
            return null;

        return licenses.filter(license => {
            if (org.licenses.includes(license._id) && license.endTime * 1000 > ts)
                return true;
        }).map(license => (
            <p key={license._id}>
                {
                    `ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`
                }
            </p>
        ))
    }
    if (Array.isArray(orgs) && orgs.length > 0)
        return orgs.slice(page * orgsPerPage, page * orgsPerPage + orgsPerPage)
            .map(
                org => {
                    if (!_user.perms.includes(USER_PERMS.ORGS)) {
                        if (org._id === userOrg._id) {
                            return (
                                <TableRow key={org._id}>
                                    <TableCell align="center">{org.name}</TableCell>
                                    {
                                        isPoolLicense ?
                                            <>
                                                <TableCell align="center">{org.participants}</TableCell>
                                                {_multiDomain ?
                                                    <TableCell align="center">{org.domain.trim() !== '' ? org.domain : _defaultDomain}</TableCell>
                                                    :
                                                    _LDAPEnabled ?
                                                        <TableCell align="center">{org.bindDN !== '' ? org.bindDN : '-'}</TableCell>
                                                        :
                                                        <TableCell align="center">{org.meetsPerTime}</TableCell>
                                                }
                                            </>
                                            :
                                            <TableCell align="center">{renderLicenses(org)}</TableCell>
                                    }
                                    <TableCell align="center">
                                        {prettyDateTime(org.exp).split(' ')[0]}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Users users={org.users} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Actions org={org} />
                                    </TableCell>
                                </TableRow>
                            )
                        }

                    } else {
                        return (
                            <TableRow key={org._id}>
                                <TableCell align="center">{org.name}</TableCell>
                                {
                                    isPoolLicense ?
                                        <>
                                            <TableCell align="center">{org.participants}</TableCell>

                                            {_multiDomain ?
                                                <TableCell align="center">{org.domain.trim() !== '' ? org.domain : _defaultDomain}</TableCell>
                                                :
                                                _LDAPEnabled ?
                                                    <TableCell align="center">{org.bindDN !== '' ? org.bindDN : '-'}</TableCell>
                                                    :
                                                    <TableCell align="center">{org.meetsPerTime}</TableCell>
                                            }


                                        </>
                                        :
                                        <TableCell align="center">{renderLicenses(org)}</TableCell>
                                }
                                <TableCell align="center">
                                    {prettyDateTime(org.exp).split(' ')[0]}
                                </TableCell>
                                <TableCell align="center">
                                    <Users users={org.users} defaultName={org.name === 'default'} />
                                </TableCell>
                                <TableCell align="center" sx={{ width: '16%' }}>
                                    <Actions org={org} />
                                </TableCell>
                            </TableRow>
                        )
                    }
                }
            )

    return (
        <TableRow>
            <TableCell colSpan={isPoolLicense ? 6 : 5} className="empty"> {t('orgs.empty')}</TableCell>
        </TableRow>
    )

}

export default withTranslation()(Items);
